import { useNavigate } from 'react-router';
import { create } from 'zustand';

import { useQueryClient } from '@tanstack/react-query';

import RoutePaths from '../routes/RoutePaths';
import { login, refreshLogin, resendOtp, signup, verifyOtp } from '../services/auth';
import type { PlanTypes } from '../services/billing';
import { Storage } from '../services/storage';
import { useSideBarStore } from './SideBarStore';

/**
 * Represents a user in the authentication store.
 *
 * @interface User
 * @property {string} [name] - The name of the user (optional).
 * @property {string} email - The email address of the user.
 * @property {string} id - The unique identifier of the user.
 * @property {boolean} onboard_user - Indicates whether the user should be shown the onboarding flow.
 * @property {PlanTypes} subscription - The subscription plan type of the user.
 */
export interface User {
  name?: string;
  email: string;
  id: string;
  onboard_user: boolean;
  subscription: PlanTypes;
}

/**
 * Represents the authentication state and actions for the application.
 *
 * @interface AuthSliceState
 * @property {boolean} isLoggedIn - Indicates if the user is currently logged in.
 * @property {boolean} isOnboardingCompleted - Indicates if the user has completed the onboarding process.
 * @property {User | null} user - The current user object or null if no user is logged in.
 * @property {(email: string) => Promise<void>} handleLogin - Function to log in a user with their email.
 * @property {(email: string, name?: string) => Promise<void>} handleSignup - Function to sign up a new user with their email and optional name.
 * @property {(otp: string) => Promise<User> | Promise<void>} handleVerifyOtp - Function to verify the OTP (One-Time Password) and return the user or void.
 * @property {() => Promise<void>} handleResendOtp - Function to resend the OTP to the user.
 * @property {() => Promise<void>} handleRefreshLogin - Function to refresh the user's tokens.
 * @property {() => Promise<void>} handleLogout - Function to log out the current user.
 */
interface AuthSliceState {
  isLoggedIn: boolean;
  isOnboardingCompleted: boolean;
  user: User | null;
  handleLogin: (email: string) => Promise<void>;
  handleSignup: (email: string, name?: string) => Promise<void>;
  handleVerifyOtp: (otp: string) => Promise<User> | Promise<void>;
  handleResendOtp: () => Promise<void>;
  handleRefreshLogin: () => Promise<void>;
  handleLogout: () => Promise<void>;
}

/**
 * A Zustand store for managing authentication state.
 *
 * @remarks
 * This store provides state management for user authentication, including login, signup,
 * OTP verification, OTP resend, and logout functionalities. It also manages the user's
 * authentication status and onboarding completion status.
 *
 * @example
 * ```typescript
 * const { isLoggedIn, isOnboardingCompleted, user, handleLogin, handleSignup, handleVerifyOtp, handleResendOtp, handleLogout } = useAuthSlice();
 * ```
 *
 * @returns {object} The authentication store with the following properties:
 * - `isLoggedIn`: A boolean indicating if the user is logged in.
 * - `isOnboardingCompleted`: A boolean indicating if the user has completed onboarding.
 * - `user`: The current authenticated user.
 * - `handleLogin`: An async function to handle user login.
 * - `handleSignup`: An async function to handle user signup.
 * - `handleVerifyOtp`: An async function to handle OTP verification.
 * - `handleResendOtp`: An async function to handle OTP resend.
 * - `handleRefreshLogin`: An async function to refresh the user's login.
 * - `handleLogout`: An async function to handle user logout.
 */
const useAuthSlice = create<AuthSliceState>((set) => {
  const handleLogin = async (email: string) => {
    try {
      const res = await login(email);
      if (res?.otp_token) {
        Storage.setOtpToken(res?.otp_token);
        return await Promise.resolve();
      }
      return await Promise.reject(new Error('There is an error while login. Please try again.'));
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const handleSignup = async (email: string, name?: string) => {
    try {
      const res = await signup(email, name);
      if (res?.otp_token) {
        Storage.setOtpToken(res?.otp_token);
        return await Promise.resolve();
      }
      return await Promise.reject(new Error('There is an error while login. Please try again.'));
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const handleVerifyOtp = async (otp: string) => {
    try {
      const res = await verifyOtp(otp);

      if (res?.user?.onboard_user) {
        set({ isOnboardingCompleted: res?.user?.onboard_user === false });
      }
      if (res?.access_token) {
        Storage.removeOtpToken();
        Storage.setJwtToken(res?.access_token);
        set({ user: res?.user, isLoggedIn: true });
        Storage.setCurrentUser(res?.user);

        if (res?.refresh_token) {
          Storage.setRefreshToken(res?.refresh_token);
        }

        return await Promise.resolve(res?.user);
      }
      return await Promise.reject(new Error('Invalid OTP'));
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const handleResendOtp = async () => resendOtp();
  const handleRefreshLogin = async () => refreshLogin();

  const handleLogout = async () => {
    Storage.clear(); // clear all local storage
    set({ user: null, isLoggedIn: false });
    Promise.resolve();
  };

  return {
    isLoggedIn: !!Storage.getJwtToken(),
    isOnboardingCompleted: Storage.getCurrentUser()?.onboard_user === false,
    user: Storage.getCurrentUser(),
    handleLogin,
    handleSignup,
    handleVerifyOtp,
    handleResendOtp,
    handleRefreshLogin,
    handleLogout,
  };
});

/**
 * Custom hook to manage authentication state and actions.
 *
 * This hook provides various authentication-related states and functions,
 * such as login, signup, OTP verification, and logout. It also handles
 * side effects like resetting the sidebar store, clearing the query client,
 * and navigating to the sign-in page upon logout.
 *
 * @returns {object} An object containing authentication states and functions:
 * - `isLoggedIn` (boolean): Indicates if the user is logged in.
 * - `isOnboardingCompleted` (boolean): Indicates if the user has completed onboarding.
 * - `user` (object): The authenticated user's information.
 * - `login` (function): Function to handle user login.
 * - `signup` (function): Function to handle user signup.
 * - `verifyOtp` (function): Function to handle OTP verification.
 * - `resendOtp` (function): Function to handle resending OTP.
 * - `logout` (function): Async function to handle user logout, reset the sidebar store, clear the query client, and navigate to the sign-in page.
 *
 * @example
 * ```tsx
 * import { useAuthStore } from './path/to/AuthStore';
 *
 * const MyComponent = () => {
 *   const { isLoggedIn, login, logout } = useAuthStore();
 *
 *   const handleLogin = async () => {
 *     await login({ username: 'user', password: 'pass' });
 *   };
 *
 *   const handleLogout = async () => {
 *     await logout();
 *   };
 *
 *   return (
 *     <div>
 *       {isLoggedIn ? (
 *         <button onClick={handleLogout}>Logout</button>
 *       ) : (
 *         <button onClick={handleLogin}>Login</button>
 *       )}
 *     </div>
 *   );
 * };
 * ```
 */
export const useAuthStore = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { reset: resetSideBarStore } = useSideBarStore();

  const {
    isLoggedIn,
    isOnboardingCompleted,
    user,
    handleLogin,
    handleSignup,
    handleVerifyOtp,
    handleResendOtp,
    handleRefreshLogin,
    handleLogout,
  } = useAuthSlice();

  return {
    isLoggedIn,
    isOnboardingCompleted,
    user,
    login: handleLogin,
    signup: handleSignup,
    verifyOtp: handleVerifyOtp,
    resendOtp: handleResendOtp,
    refreshLogin: handleRefreshLogin,
    logout: async () => {
      await handleLogout();
      resetSideBarStore();
      queryClient.clear();
      setTimeout(() => {
        navigate(RoutePaths.SIGN_IN);
      }, 500);
    },
  };
};
