import { forwardRef } from 'react';
import type { UseFormRegister } from 'react-hook-form';

import { Checkbox, FormControlLabel, Grid, Stack, Typography } from '@mui/material';

import type { JDKeywordOptions } from '../../../services/resumes';

/**
 * Props for the SelectKeywordsFormContent component.
 */
interface SelectKeywordsFormContentProps {
  options?: JDKeywordOptions;
  register: UseFormRegister<JDKeywordOptions>;
}

/**
 * Component for displaying a form to select keywords tailored for ATS (Applicant Tracking System).
 * The keywords are extracted from the job description and presented in a two-column grid with checkboxes.
 *
 * @component
 * @param {SelectKeywordsFormContentProps} props - The props for the component.
 * @param {object} props.options - An object containing categories of keywords and their checked status.
 * @param {function} props.register - A function to register the input fields with react-hook-form.
 *
 * @example
 * const options = {
 *   skills: { JavaScript: true, TypeScript: false },
 *   tools: { Git: true, Docker: false }
 * };
 * const register = () => {};
 *
 * <SelectKeywordsFormContent options={options} register={register} />
 */
const SelectKeywordsFormContent = forwardRef<
  { [key: string]: HTMLInputElement },
  SelectKeywordsFormContentProps
>(({ options, register }, ref) => (
  <Stack gap={3}>
    Confirm keywords to tailor your resume for ATS. These keywords have been extracted from the job
    description.
    <form onSubmit={(e) => e.preventDefault()}>
      <Stack sx={{ gap: 2.5 }}>
        {
          // Two column grid and add checkboxes for each keyword in each column
          <Grid
            container
            spacing={2}
            sx={{
              maxHeight: '22.5rem',
              overflowY: 'auto',
            }}
          >
            {options &&
              Object.entries(options).map(([category, keywords]) => (
                <Grid item xs={12} sm={6} key={category}>
                  <Stack gap={1}>
                    {Object.entries(keywords).map(([keyword, checked]) => (
                      <FormControlLabel
                        key={keyword}
                        control={
                          <Checkbox
                            {...register(
                              `${category}.${keyword}` as
                                | 'skills_Keywords'
                                | 'responsibilities_Keywords'
                                | `skills_Keywords.${string}`
                                | `responsibilities_Keywords.${string}`,
                            )}
                            defaultChecked={checked as boolean}
                            inputRef={(el) => {
                              if (
                                ref &&
                                'current' in ref &&
                                ref?.current &&
                                typeof ref.current === 'object' &&
                                el
                              ) {
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                (ref.current as any)[`${category}.${keyword}`] = el;
                              }
                            }}
                          />
                        }
                        label={<Typography variant="body3">{keyword}</Typography>}
                      />
                    ))}
                  </Stack>
                </Grid>
              ))}
          </Grid>
        }
      </Stack>
    </form>
  </Stack>
));

export default SelectKeywordsFormContent;
