import { ResumeKey } from '../../interface';

export const ResumeEditorMap = {
  // TODO: replace id fields with ResumeKey keys
  // TODO: handle for add/edit actions based on Resume Editor State
  //   TODO: add success, error snakcbar messages
  id: { breadCrumb: '', sectionKey: ResumeKey.RESUME_ID },
  filename: { breadCrumb: '', sectionKey: ResumeKey.FILENAME },
  contact: { breadCrumb: 'Contact Information', sectionKey: ResumeKey.CONTACT },
  [ResumeKey.HERO]: { breadCrumb: 'Hero Message', sectionKey: ResumeKey.HERO },
  [ResumeKey.OBJECTIVE]: { breadCrumb: 'Objective', sectionKey: ResumeKey.OBJECTIVE },
  summary: { breadCrumb: 'Professional Summary', sectionKey: ResumeKey.SUMMARY },
  'work-experience': { breadCrumb: 'Work Experience', sectionKey: ResumeKey.EXPERIENCE },
  'career-break': { breadCrumb: 'Career Break', sectionKey: ResumeKey.EXPERIENCE },
  skills: { breadCrumb: 'Skills', sectionKey: ResumeKey.SKILLS },
  education: { breadCrumb: 'Education', sectionKey: ResumeKey.EDUCATION },
  project: { breadCrumb: 'Project', sectionKey: ResumeKey.PROJECT },
  coursework: { breadCrumb: 'Coursework', sectionKey: ResumeKey.COURSEWORK },
  interests: { breadCrumb: 'Interests', sectionKey: ResumeKey.INTERESTS },
  patents: { breadCrumb: 'Patents', sectionKey: ResumeKey.PATENTS },
  publications: { breadCrumb: 'Publications', sectionKey: ResumeKey.PUBLICATIONS },
  'volunteer-experience': {
    breadCrumb: 'Volunteer Experience',
    sectionKey: ResumeKey.VOLUNTEER_EXPERIENCE,
  },
  [ResumeKey.ACHIEVEMENTS]: {
    breadCrumb: 'Achievements',
    sectionKey: ResumeKey.ACHIEVEMENTS,
  },
  [ResumeKey.CERTIFICATES]: {
    breadCrumb: 'Certifications and Licenses',
    sectionKey: ResumeKey.CERTIFICATES,
  },
};
