import { isNotNil } from 'ramda';
import { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';

import type { SxProps } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import { useResumeEditorStore } from '../../stores/ResumeEditorStore';
import theme from '../../theme/theme';
import type { ButtonProps } from '../common/Button';
import Button from '../common/Button';

/**
 * Props for the FormActions component.
 */
interface FormActionsProps {
  /**
   * Optional custom actions to be rendered.
   */
  actions?: React.ReactNode;

  /**
   * Optional callback function for cancel action.
   */
  onCancel?: () => void;

  /**
   * Optional callback function for submit action.
   */
  onSubmit?: () => void;

  /**
   * Additional styling properties.
   */
  sx?: SxProps;

  /**
   * Additional props for the submit button.
   */
  submitButtonProps?: ButtonProps;

  /**
   * Ref for the root div element.
   */
  rootRef?: React.Ref<HTMLDivElement> | null;
}

/**
 * Component for rendering form actions (cancel and submit buttons).
 *
 * @param {FormActionsProps} props - The properties for the component.
 * @param {React.ReactNode} [props.actions] - Optional custom actions to be rendered.
 * @param {() => void} [props.onCancel] - Optional callback function for cancel action.
 * @param {() => void} [props.onSubmit] - Optional callback function for submit action.
 * @param {SxProps} [props.sx] - Additional styling properties.
 * @param {ButtonProps} [props.submitButtonProps] - Additional props for the submit button.
 * @param {React.Ref<HTMLDivElement> | null} [props.rootRef] - Ref for the root div element.
 * @returns {JSX.Element} The rendered FormActions component.
 *
 * @example
 * // Usage example
 * import FormActions from './FormActions';
 *
 * const MyForm = () => {
 *   const handleCancel = () => {
 *     console.log('Cancel clicked');
 *   };
 *
 *   const handleSubmit = () => {
 *     console.log('Submit clicked');
 *   };
 *
 *   return (
 *     <form>
 *        // Form input fields go here
 *       <FormActions
 *         onCancel={handleCancel}
 *         onSubmit={handleSubmit}
 *         submitButtonProps={{ loading: false }}
 *       />
 *     </form>
 *   );
 * };
 */
const FormActions = forwardRef<HTMLDivElement, FormActionsProps>(
  ({ actions, onCancel, onSubmit, sx, submitButtonProps }, ref) => {
    const navigate = useNavigate();
    const { isMutating } = useResumeEditorStore();

    // Default cancel handler that navigates back
    const onCancelHandler = onCancel || (() => navigate(-1));

    return (
      <Grid2
        spacing={1.5}
        sx={{
          justifyContent: { xs: 'center', sm: 'flex-end' },
          ...sx,
        }}
        ref={ref}
        container
      >
        {isNotNil(actions) ? (
          // Render custom actions if provided
          <Grid2 sx={{ width: 'inherit' }}>{actions}</Grid2>
        ) : (
          <>
            {/* Cancel button */}
            <Grid2 sx={{ width: { xs: '30%', sm: 'max-content' } }}>
              <Button
                variant="outlined"
                onClick={onCancelHandler}
                sx={{
                  width: '100%',
                  height: '100%',
                  padding: { xs: 2, sm: `${theme.spacing(1)} ${theme.spacing(1.5)}` },
                }}
              >
                Cancel
              </Button>
            </Grid2>
            {/* Submit button */}
            <Grid2 sx={{ width: { xs: '70%', sm: 'max-content' } }}>
              <Button
                type="submit"
                onClick={onSubmit}
                loading={isMutating || submitButtonProps?.loading}
                sx={{
                  width: '100%',
                  height: '100%',
                  padding: { xs: 2, sm: `${theme.spacing(1)} ${theme.spacing(1.5)}` },
                }}
                {...submitButtonProps}
              >
                Save
              </Button>
            </Grid2>
          </>
        )}
      </Grid2>
    );
  },
);

export default FormActions;
