import { isEmpty, isNil } from 'ramda';
import { useEffect, useMemo } from 'react';
import type { Control, FieldValues, SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import { Checkbox, InputLabel, Stack, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import { degreeList, monthList, yearsListFrom1971 } from '../../../constants';
import type { EducationInfo } from '../../../interface';
import { isNotNilOrEmpty, validateGpa, validateTotalGpa } from '../../../utils';
import {
  filterMonthList,
  filterYearList,
  getIntegerMonth,
  getStringMonth,
  isValidMonth,
  validateEndYear,
  validateStartYear,
} from '../../../utils/dateUtils';
import FormActions from '../../Form/FormActions';
import Dropdown from '../../common/Dropdown';

interface EducationEditorProps {
  onUpdate: (education: EducationInfo, method: string) => void;
  defaultEducation?: EducationInfo;
}

const EducationEditor = ({ onUpdate, defaultEducation }: EducationEditorProps) => {
  const method = defaultEducation?._id === '' ? 'add' : 'edit';
  const FormDefaultValues = useMemo(
    () => ({
      _id: defaultEducation?._id || '',
      degree: defaultEducation?.degree || '',
      major: defaultEducation?.major || '',
      school: defaultEducation?.school || '',
      gpa: defaultEducation?.gpa || null,
      total_gpa: defaultEducation?.total_gpa || null,
      present: defaultEducation?.present || false,
      start_month: getStringMonth(defaultEducation?.start_month),
      start_year: defaultEducation?.start_year,
      end_month: getStringMonth(defaultEducation?.end_month),
      end_year: defaultEducation?.end_year,
      index: defaultEducation?.index || 0,
    }),
    [defaultEducation],
  );

  const {
    register,
    handleSubmit,
    getValues,
    clearErrors,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: FormDefaultValues,
  });

  const isStudying = watch('present');

  // Refresh form values on defeaultWorkExperience changes; usually happens on page refresh
  useEffect(() => {
    if (isNotNilOrEmpty(defaultEducation) && method === 'edit') {
      reset(FormDefaultValues);
    }
  }, [FormDefaultValues, defaultEducation, method, reset]);

  const onSubmit: SubmitHandler<FieldValues> = (data: FieldValues) => {
    onUpdate(
      {
        _id: data._id,
        degree: data.degree,
        major: data.major,
        school: data.school,
        gpa: data.gpa,
        total_gpa: data.total_gpa,
        present: data.present,
        start_year: data.start_year === '' ? null : data.start_year,
        end_year: data.end_year === '' ? null : data.end_year,
        start_month:
          isValidMonth(data.start_month) || isEmpty(data.start_month)
            ? getIntegerMonth(data.start_month)
            : (defaultEducation?.start_month as number | null),
        end_month:
          isValidMonth(data.end_month) || isEmpty(data.end_month)
            ? getIntegerMonth(data.end_month)
            : (defaultEducation?.end_month as number | null),
        index: data.index,
      },
      method,
    );
  };

  const onValidateStartYear = (startYear: number | null | undefined) => {
    clearErrors('end_year');
    return validateStartYear(
      getValues('start_month'),
      startYear,
      getValues('end_month'),
      getValues('end_year'),
      getValues('present'),
    );
  };

  const onValidateEndYear = (endYear: number | null | undefined) => {
    if (errors?.start_year) {
      return true;
    }
    return validateEndYear(getValues('start_year'), getValues('end_month'), endYear);
  };

  const onFilterStartYearList = (startYear: number) =>
    filterYearList(
      getValues('start_month'),
      startYear,
      getValues('end_month'),
      getValues('end_year'),
      getValues('present'),
    );

  const onFilterEndYearList = (endYear: number) =>
    filterYearList(
      getValues('start_month'),
      getValues('start_year'),
      getValues('end_month'),
      endYear,
      getValues('present'),
    );

  const onFilterStartMonthList = (startMonth: string) =>
    filterMonthList(
      startMonth,
      getValues('start_year'),
      getValues('end_month'),
      getValues('end_year'),
      getValues('present'),
    );

  const onFilterEndMonthList = (endMonth: string) =>
    filterMonthList(
      getValues('start_month'),
      getValues('start_year'),
      endMonth,
      getValues('end_year'),
      getValues('present'),
    );

  const onValidateGpa = (type: string) => {
    const gpa = getValues('gpa');
    const totalGpa = getValues('total_gpa');
    if ((isNil(gpa) || Number.isNaN(gpa)) && (isNil(totalGpa) || Number.isNaN(totalGpa))) {
      clearErrors('gpa');
      clearErrors('total_gpa');
      return true;
    }
    if (type === 'gpa') {
      const isValid = validateGpa(gpa, totalGpa);
      if (typeof isValid === 'string') {
        clearErrors('total_gpa');
      }
      return isValid;
    }
    if (type === 'total_gpa') {
      return validateTotalGpa(gpa, totalGpa);
    }
    return true;
  };

  // TODO: Refactor code
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap="24px">
        <Stack gap="8px">
          <InputLabel
            htmlFor="degree"
            sx={{
              color: 'error.main',
              '& .MuiFormLabel-asterisk': { paddingLeft: 0.5, fontSize: '12px' },
            }}
            required
          >
            <Typography variant="label2" color="text.primary">
              Degree
            </Typography>
          </InputLabel>
          <Dropdown
            {...register('degree', {
              required: 'Select a degree',
            })}
            items={degreeList}
            placeholder="Select Degree"
            defaultValue={defaultEducation?.degree}
            control={control as unknown as Control<FieldValues>}
            freeSolo
          />
        </Stack>
        <Stack gap="8px">
          <InputLabel
            sx={{
              color: 'error.main',
              '& .MuiFormLabel-asterisk': { paddingLeft: 0.5, fontSize: '12px' },
            }}
          >
            <Typography variant="label2" color="text.primary">
              Major
            </Typography>
          </InputLabel>
          <TextField
            id="major"
            placeholder="Enter major"
            {...register('major')}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid',
                  borderColor: 'border.light',
                  borderRadius: '8px',
                  padding: '10px',
                },
              },
            }}
            error={!!errors?.major}
            variant="outlined"
            fullWidth
          />
          {!!errors?.major && (
            <Typography variant="assistive" color="error">
              {errors?.major?.message?.toString()}
            </Typography>
          )}
        </Stack>
        <Stack gap="8px">
          <InputLabel>
            <Typography variant="label2" color="text.primary">
              School
            </Typography>
          </InputLabel>
          <TextField
            id="school"
            placeholder="Enter School"
            {...register('school')}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid',
                  borderColor: 'border.light',
                  borderRadius: '8px',
                  padding: '10px',
                },
              },
            }}
            variant="outlined"
            fullWidth
          />
        </Stack>
        <Grid2 alignItems="center" spacing="8px" container>
          <Grid2>
            <Checkbox
              checked={isStudying}
              {...register('present')}
              color="primary"
              sx={{ padding: 0, paddingRight: 1 }}
            />
          </Grid2>
          <Grid2>
            <Typography variant="body3">I currently study here</Typography>
          </Grid2>
        </Grid2>
        <Stack gap="8px">
          <InputLabel>
            <Typography variant="label2" color="text.primary">
              From
            </Typography>
          </InputLabel>
          <Grid2 justifyContent="space-between" spacing="8px" container>
            <Grid2 xs={6}>
              <Dropdown
                {...register('start_month')}
                items={monthList}
                placeholder="Select Start Month"
                defaultValue={getStringMonth(defaultEducation?.start_month)}
                control={control as unknown as Control<FieldValues>}
                disableOptionfilter={onFilterStartMonthList}
              />
            </Grid2>
            <Grid2 xs={6}>
              <Dropdown
                {...register('start_year', { validate: onValidateStartYear })}
                items={yearsListFrom1971}
                placeholder="Select Start Year"
                defaultValue={defaultEducation?.start_year}
                control={control as unknown as Control<FieldValues>}
                filterOptions={(year) => !onFilterStartYearList(year as number)}
              />
            </Grid2>
          </Grid2>
        </Stack>
        {!isStudying && (
          <Stack gap="8px">
            <InputLabel>
              <Typography variant="label2" color="text.primary">
                To
              </Typography>
            </InputLabel>
            <Grid2 justifyContent="space-between" spacing="8px" container>
              <Grid2 xs={6}>
                <Dropdown
                  {...register('end_month')}
                  items={monthList}
                  placeholder="Select End Month"
                  defaultValue={getStringMonth(defaultEducation?.end_month)}
                  control={control as unknown as Control<FieldValues>}
                  disableOptionfilter={onFilterEndMonthList}
                />
              </Grid2>
              <Grid2 xs={6}>
                <Dropdown
                  {...register('end_year', { validate: onValidateEndYear })}
                  items={yearsListFrom1971}
                  placeholder="Select End Year"
                  defaultValue={defaultEducation?.end_year}
                  control={control as unknown as Control<FieldValues>}
                  filterOptions={(year) => !onFilterEndYearList(year as number)}
                />
              </Grid2>
            </Grid2>
          </Stack>
        )}
        <Stack gap={1}>
          <InputLabel htmlFor="gpa">
            <Typography variant="label2" color="text.primary">
              GPA
            </Typography>
          </InputLabel>
          <Grid2
            sm={6}
            xs={12}
            sx={{ paddingRight: 1, justifyContent: { xs: 'center', sm: 'space-between' } }}
            container
          >
            <Grid2 xs={12} sm={5.65} flexGrow={1}>
              {/* TODO: Use common TextField component */}
              <TextField
                id="gpa"
                placeholder="3.7"
                {...register('gpa', { valueAsNumber: true, validate: () => onValidateGpa('gpa') })}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '1px solid',
                      borderColor: 'border.light',
                      borderRadius: '8px',
                      padding: '10px',
                    },
                  },
                }}
                defaultValue={defaultEducation?.gpa}
                error={!!errors.gpa}
                variant="outlined"
                fullWidth
              />
            </Grid2>
            <Grid2 sx={{ color: 'text.tertiary', my: 'auto' }}>
              <Typography variant="body1">/</Typography>
            </Grid2>
            <Grid2 xs={12} sm={5.65} flexGrow={1}>
              <TextField
                id="total_gpa"
                placeholder="4.0"
                {...register('total_gpa', {
                  valueAsNumber: true,
                  validate: () => onValidateGpa('total_gpa'),
                })}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '1px solid',
                      borderColor: 'border.light',
                      borderRadius: '8px',
                      padding: '10px',
                    },
                  },
                }}
                defaultValue={defaultEducation?.total_gpa}
                error={!!errors.total_gpa}
                variant="outlined"
                fullWidth
              />
            </Grid2>
          </Grid2>
          <Grid2 container>
            <Grid2 sm={errors?.gpa ? 12 : 3.15}>
              {!!errors?.gpa && (
                <Typography variant="assistive" color="error">
                  {errors?.gpa?.message?.toString()}
                </Typography>
              )}
            </Grid2>
            <Grid2>
              {!!errors?.total_gpa && !!errors?.gpa === false && (
                <Typography variant="assistive" color="error">
                  {errors?.total_gpa?.message?.toString()}
                </Typography>
              )}
            </Grid2>
          </Grid2>
        </Stack>
        <FormActions sx={{ marginTop: '24px' }} />
      </Stack>
    </form>
  );
};

export default EducationEditor;
