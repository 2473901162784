import { useState } from 'react';

import { Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { addSeparator } from '../../../utils';
import SelectCard from '../../common/SelectCard';
import { AddJobOptions, SelectJDSection } from '../constants';
import type { ImportJobDescriptionProps } from './ImportJobDescription';
import ImportJobDescription from './ImportJobDescription';
import type { SelectJobXrayProps } from './SelectJobXray';
import SelectJobXray from './SelectJobXray';

interface JobDescriptionMobileSelectorProps {
  selectJobXrayProps: SelectJobXrayProps;
  importJobDescriptionProps: ImportJobDescriptionProps;
  value: {
    activeSection: SelectJDSection;
    jobDescriptionId: string;
    jobDescriptionText: string;
  };
  setSelectedSection: (section: SelectJDSection) => void;
}

const JobDescriptionMobileSelector = ({
  selectJobXrayProps,
  importJobDescriptionProps,
  setSelectedSection,
  value,
}: JobDescriptionMobileSelectorProps) => {
  const theme = useTheme();
  const [isCardSelected, setIsCardSelected] = useState(false);
  const [addJobOptions, setAddJobOptions] = useState(AddJobOptions);

  const handleCardClick = (key: string) => {
    setIsCardSelected(true);
    setSelectedSection(key as SelectJDSection);
  };

  const onSelectJobDescription = (data: { jdId: string }) => {
    setIsCardSelected(false);
    if (selectJobXrayProps.onSelectJD) {
      selectJobXrayProps.onSelectJD(data);
    }
    const selectedJobDescription = selectJobXrayProps.jobDescriptionList?.find(
      (jd) => jd._id === data.jdId,
    );
    if (selectedJobDescription) {
      const { company, title: position } = selectedJobDescription['job-description-xray'];
      const updatedAddJobOptions = addJobOptions.map((option) => {
        if (option.key === SelectJDSection.SELECT_JD) {
          return {
            ...option,
            description: addSeparator(company, position, ' - '),
          };
        }
        return option;
      });
      setAddJobOptions(updatedAddJobOptions);
    }
  };

  const onImportJobDescription = (data: { jdId?: string; jDText?: string }) => {
    setIsCardSelected(false);
    if (importJobDescriptionProps.onImport) {
      importJobDescriptionProps.onImport(data);
    }
  };

  return (
    <>
      <Grid spacing={2} container sx={{ justifyContent: 'center', width: '100%', margin: 0 }}>
        <Grid xs={12} sx={{ paddingTop: 0, paddingBottom: 3 }}>
          <Typography variant="h3" sx={{ textAlign: { xs: 'center', md: 'left' } }}>
            Add Job Details
          </Typography>
        </Grid>
        {addJobOptions.map((item) => (
          <Grid
            key={item.key}
            xs={12}
            md={6}
            lg={4}
            sx={{ [theme.breakpoints.down('md')]: { paddingX: 0 } }}
          >
            <SelectCard
              cardContent={item}
              sx={{
                borderColor:
                  (item.key === SelectJDSection.SELECT_JD &&
                    value.activeSection === SelectJDSection.SELECT_JD &&
                    value.jobDescriptionId !== '') ||
                  (item.key === SelectJDSection.IMPORT_JD &&
                    value.activeSection === SelectJDSection.IMPORT_JD &&
                    value.jobDescriptionText !== '')
                    ? 'primary.main'
                    : 'border.light',
              }}
              onClick={handleCardClick}
              enableDescription={
                (item.key === SelectJDSection.SELECT_JD && value.jobDescriptionId !== '') ||
                (item.key === SelectJDSection.IMPORT_JD && value.jobDescriptionText !== '')
              }
            />
          </Grid>
        ))}
      </Grid>
      <SelectJobXray
        {...selectJobXrayProps}
        onClose={() => setIsCardSelected(false)}
        onSelectJD={onSelectJobDescription}
        isSectionEnabled={selectJobXrayProps.isSectionEnabled && isCardSelected}
      />
      <ImportJobDescription
        {...importJobDescriptionProps}
        onClose={() => setIsCardSelected(false)}
        onImport={onImportJobDescription}
        isSectionEnabled={importJobDescriptionProps.isSectionEnabled && isCardSelected}
      />
    </>
  );
};

export default JobDescriptionMobileSelector;
