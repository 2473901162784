import { useRef } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import { Stack } from '@mui/material';

import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import type { JDKeywordOptions } from '../../../services/resumes';
import theme from '../../../theme/theme';
import Button from '../../common/Button';
import Icon from '../../common/Icon';
import Modal from '../../common/Modal/Modal';
import ModalActions from '../../common/Modal/ModalActions';
import ModalDetail from '../../common/Modal/ModalDetail';
import SelectKeywordsFormContent from './SelectKeywordsFormContent';

/**
 * Props for the SelectKeywordForm component.
 */
interface SelectKeywordFormProps {
  open: boolean;
  isLoading?: boolean;
  options?: JDKeywordOptions;
  onSubmit: SubmitHandler<JDKeywordOptions>;
  onClose: () => void; // Add onClose prop
}

/**
 * SelectKeywordForm component renders a modal form for selecting keywords.
 *
 * @param {Object} props - The properties object.
 * @param {boolean} props.open - Determines if the modal is open.
 * @param {boolean} props.isLoading - Indicates if the form is in a loading state.
 * @param {Object} props.options - The options for the keywords.
 * @param {Function} props.onSubmit - The function to call when the form is submitted.
 * @param {Function} props.onClose - The function to call when the modal is closed.
 *
 * @returns {JSX.Element} The rendered SelectKeywordForm component.
 *
 * @component
 * @example
 * const options = {
 *   skills_Keywords: { keyword1: true, keyword2: false },
 *   responsibilities_Keywords: { keyword3: true, keyword4: false }
 * };
 * const handleSubmit = (data) => console.log(data);
 * const handleClose = () => console.log('Modal closed');
 *
 * <SelectKeywordForm
 *   open={true}
 *   isLoading={false}
 *   options={options}
 *   onSubmit={handleSubmit}
 *   onClose={handleClose}
 * />
 */
const SelectKeywordForm = ({
  open,
  isLoading,
  options,
  onSubmit,
  onClose,
}: SelectKeywordFormProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<JDKeywordOptions>({
    mode: 'onChange', // Trigger validation on change
    criteriaMode: 'all', // Evaluate all validation criteria
    resolver: async (data) => {
      const skillsChecked = data?.skills_Keywords
        ? Object.values(data.skills_Keywords).some(Boolean)
        : false;
      const responsibilitiesChecked = data?.responsibilities_Keywords
        ? Object.values(data.responsibilities_Keywords).some(Boolean)
        : false;
      return {
        values: data,
        errors:
          !skillsChecked && !responsibilitiesChecked
            ? {
                formError: {
                  type: 'manual',
                  message: 'At least one checkbox must be checked',
                },
              }
            : {},
      };
    },
  });

  const { isMobileOrTablet, isMobile } = useResponsiveDevice();

  // Ref to store the input elements for the options
  const optionsRefs = useRef<{ [key: string]: HTMLInputElement }>({});

  /**
   * Deselects all checkboxes
   *
   * Worked by iterating over the refs and clicking on each checked checkbox.
   *
   * This solution is used because the checkboxes are controlled by Material-UI (MUI) and React Hook Form (RHF).
   *
   * The checkboxes are created dynamically based on the options object. The ref is forwarded to the child component,
   * and the child attaches each dynamic element's ref as a property to this ref.current object. The parent clicks on
   * each element to set them to an unchecked state.
   *
   * Note: MUI controls the style by adding classes on click, so it won't show the unchecked style even if the element's
   * value was set to false by RHF.
   */
  const onUnSelectAll = () => {
    // Iterate over the refs and click on the checkbox if it is checked
    if (optionsRefs.current && Object.keys(optionsRefs.current).length > 0) {
      Object.values(optionsRefs.current).forEach((ref) => {
        if (ref.checked) {
          ref.click();
        }
      });
    }
  };

  return (
    <Modal
      open={open}
      contentSx={{
        ...(isMobileOrTablet
          ? {
              width: '100%',
              maxHeight: '94svh',
              alignSelf: 'end',
              padding: `${theme.spacing(3)} ${theme.spacing(1)}`,
              boxSizing: 'border-box',
              borderRadius: `${theme.spacing(1.5)} ${theme.spacing(1.5)} ${theme.spacing(
                0,
              )} ${theme.spacing(0)}`,
            }
          : { width: '47.5rem' }),
        gap: 4,
      }}
    >
      <>
        <Stack>
          {onClose && isMobile && (
            <Stack
              pt={0.5}
              pb={1}
              pr={1}
              id="modal-icon"
              sx={{ flexDirection: 'row', justifyContent: 'flex-end' }}
            >
              <Icon
                className="fi fi-rr-cross"
                style={{ color: theme.palette.text.secondary }}
                onClick={onClose}
              />
            </Stack>
          )}
          <ModalDetail
            title="Select Keywords"
            description={
              <SelectKeywordsFormContent options={options} register={register} ref={optionsRefs} />
            }
          />
        </Stack>
        <ModalActions
          sx={{
            paddingX: 1,
            '.action-grid-item': {
              width: '100%',
            },
          }}
          actions={
            <Stack
              sx={{
                gap: 1.5,
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Button
                variant="outlined"
                onClick={onUnSelectAll}
                sx={{
                  width: { xs: '100%', md: 'fit-content' },
                  padding: {
                    xs: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
                    sm: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
                  },
                  color: 'text.primary',
                  borderColor: 'border.light',
                  '&:hover': {
                    borderColor: 'border.main',
                  },
                }}
              >
                Unselect All
              </Button>
              <Button
                onClick={handleSubmit(onSubmit)}
                sx={{
                  width: { xs: '100%', md: 'fit-content' },
                  padding: {
                    xs: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
                    sm: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
                  },
                }}
                disabled={!isValid}
                loading={isLoading}
              >
                Add
              </Button>
            </Stack>
          }
        />
      </>
    </Modal>
  );
};

export default SelectKeywordForm;
