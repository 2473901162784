import type { ChangeEvent } from 'react';
import { useState } from 'react';

import {
  Chip,
  FormControl,
  FormHelperText,
  FormControlLabel as MuiFormControlLabel,
  Radio as MuiRadio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';

import JobDescriptionExample from '../../../components/SelectJobDescription/components/JobDescriptionExample';
import Button from '../../../components/common/Button';
import Card from '../../../components/common/Card';
import LoadingSpinner from '../../../components/common/LoadingSpinner';
import Modal from '../../../components/common/Modal/Modal';
import ModalActions from '../../../components/common/Modal/ModalActions';
import ModalDetail from '../../../components/common/Modal/ModalDetail';
import TextField from '../../../components/common/TextField';
import TextWrappedLinkButton from '../../../components/common/TextWrappedLinkButton';
import { ResponseStatus } from '../../../interface';
import { JobDescriptionTypes, getAllJobXRays } from '../../../services/jobDescriptions';

// TODO: Deprecate this old select job description component
const Radio = styled(MuiRadio)(({ theme: themeConfigs }) => ({
  width: themeConfigs.spacing(2.25),
  height: themeConfigs.spacing(2.25),
}));

const FormControlLabel = styled(MuiFormControlLabel)(({ theme: themeConfigs }) => ({
  border: `${themeConfigs.spacing(0.125)} solid ${
    (themeConfigs.palette.border as { light?: string })?.light
  }`,
  backgroundColor: (themeConfigs.palette.background as { mediumLight?: string })?.mediumLight || '',
  borderRadius: `${themeConfigs.spacing(2)} ${themeConfigs.spacing(2)} 
                 ${themeConfigs.spacing(0)} ${themeConfigs.spacing(0)}`,
  boxSizing: 'border-box',
  margin: 0,
  gap: themeConfigs.spacing(2),
  padding: themeConfigs.spacing(2),
}));

export enum SelectJDSection {
  SELECT_JD = 'select-jd',
  IMPORT_JD = 'import-jd',
}

const SelectJobDescription = ({
  onSelected,
  defaultValue,
}: {
  onSelected: (data: {
    jdId?: string;
    resumeId?: string;
    jDText?: string;
    resumeFile?: File;
  }) => void;
  defaultValue?: {
    section: SelectJDSection;
    value: string;
  };
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const theme = useTheme();

  const [selectedSection, setSelectedSection] = useState<SelectJDSection>(
    defaultValue ? defaultValue.section : SelectJDSection.SELECT_JD,
  );
  const [jobDescriptionId, setJobDescriptionId] = useState<string>(
    defaultValue?.section === SelectJDSection.SELECT_JD ? defaultValue?.value : '',
  );
  const [importJDText, setImportJDText] = useState<string>(
    defaultValue?.section === SelectJDSection.IMPORT_JD ? defaultValue?.value : '',
  );

  const { data: jDQueryResponse, isLoading } = useQuery({
    queryKey: [JobDescriptionTypes.JOBXRAYS],
    queryFn: () => getAllJobXRays(ResponseStatus.DONE),
  });

  const jobDescriptions = jDQueryResponse?.result;

  const handleSelectSelectionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedSection(event.target.value as SelectJDSection);
  };

  const handleSelectJD = (event: ChangeEvent<HTMLInputElement>) => {
    onSelected({ jdId: event.target.value });
    setJobDescriptionId(event.target.value);
  };

  const handleJDImportTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    setImportJDText(event.target.value);
    onSelected({ jDText: event.target.value });
  };

  return (
    <FormControl sx={{ height: '100%' }}>
      <RadioGroup
        defaultValue={selectedSection}
        name="select-jd-sections-group"
        onChange={handleSelectSelectionChange}
        sx={{ height: '100%' }}
      >
        <FormControlLabel
          value={SelectJDSection.SELECT_JD}
          control={<Radio />}
          label={<Typography variant="label2">Select a Job X-Ray</Typography>}
          sx={{
            backgroundColor:
              selectedSection === SelectJDSection.SELECT_JD
                ? 'background.mediumLight'
                : 'common.white',
          }}
        />
        {/* Select Jd section goes here */}
        {selectedSection === SelectJDSection.SELECT_JD && (
          // TODO: move to a separate component
          <Card
            sx={{
              height: '45vh',
              boxSizing: 'border-box',
              overflowY: 'scroll',
            }}
            cardContentProps={{
              sx: {
                padding: `${theme.spacing(1)} ${theme.spacing(2)} 
                            ${theme.spacing(1)} ${theme.spacing(4)}`,
                height: '100%',
                boxSizing: 'border-box',
              },
            }}
          >
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <FormControl sx={{ width: '100%' }}>
                <RadioGroup
                  name="select-job-descriptions-group"
                  onChange={handleSelectJD}
                  defaultValue={defaultValue?.value}
                >
                  {jobDescriptions?.map((jobDescription) => (
                    <FormControlLabel
                      key={jobDescription._id}
                      value={jobDescription._id}
                      control={<Radio />}
                      label={
                        <Stack
                          sx={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: 0.75,
                          }}
                        >
                          <Chip
                            label={
                              <Typography
                                variant={
                                  jobDescriptionId === jobDescription._id
                                    ? 'assistiveBold'
                                    : 'assistive'
                                }
                              >
                                {jobDescription['job-description-xray']?.company || '-'}
                              </Typography>
                            }
                            sx={{
                              ...(jobDescriptionId === jobDescription._id
                                ? {
                                    backgroundColor: 'primary.mediumLight',
                                    color: 'primary.mediumDark',
                                  }
                                : {
                                    backgroundColor: 'background.main',
                                    color: 'inherit',
                                  }),
                              borderRadius: 4,
                              height: theme.spacing(3),
                              '& .MuiChip-label': {
                                padding: `${theme.spacing(0)} ${theme.spacing(1)}`,
                              },
                            }}
                          />
                          <Typography variant="body3">
                            {jobDescription['job-description-xray']?.title || '-'}
                          </Typography>
                        </Stack>
                      }
                      sx={{
                        gap: 1,
                        border: 'none',
                        borderRadius: 2,
                        padding: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
                        ':hover': {
                          backgroundColor: 'background.main',
                        },
                        backgroundColor:
                          jobDescriptionId === jobDescription._id
                            ? 'primary.light'
                            : 'common.white',
                      }}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            )}
          </Card>
        )}
        <FormControlLabel
          value={SelectJDSection.IMPORT_JD}
          control={<Radio />}
          label={<Typography variant="label2">Import a job</Typography>}
          sx={{
            ...(selectedSection === SelectJDSection.IMPORT_JD
              ? {
                  borderRadius: 0,
                  backgroundColor: 'background.mediumLight',
                }
              : {
                  borderRadius: `${theme.spacing(0)} ${theme.spacing(0)} 
                                   ${theme.spacing(2)} ${theme.spacing(2)}`,
                  backgroundColor: 'common.white',
                }),
          }}
        />
        {/* Import JD goes here */}
        {selectedSection === SelectJDSection.IMPORT_JD && (
          <Stack
            sx={{
              border: `${theme.spacing(0.125)} solid`,
              borderColor: 'border.light',
              borderRadius: `${theme.spacing(0)} ${theme.spacing(0)} 
                               ${theme.spacing(2)} ${theme.spacing(2)}`,
              padding: `${theme.spacing(3)} ${theme.spacing(4)} 
                          ${theme.spacing(4)} ${theme.spacing(6)}`,
              minHeight: '45vh',
              boxSizing: 'border-box',
            }}
          >
            <FormHelperText sx={{ margin: 0, marginBottom: 1 }}>
              <Typography variant="body3">
                <TextWrappedLinkButton
                  variant="body3"
                  text={{
                    startingText:
                      'Copy and paste the entire job description from the job posting, including the company name and job title. You don’t have to clean up the text. ',
                    linkText: 'Example',
                    endingText: '',
                  }}
                  onClick={() => setShowModal(true)}
                />
              </Typography>
            </FormHelperText>
            <Modal open={showModal} contentSx={{ width: '80vw', maxWidth: '46.5rem' }}>
              <>
                <ModalDetail
                  title="Example Job Posting from LinkedIn"
                  description={<JobDescriptionExample />}
                />
                <ModalActions
                  actions={
                    <Button color="primary" onClick={() => setShowModal(false)}>
                      Close
                    </Button>
                  }
                />
              </>
            </Modal>
            {/* Job Description text input field*/}
            <TextField
              type="textarea"
              multiline
              minRows={10}
              maxRows={14}
              sx={{
                '& .MuiOutlinedInput-root': {
                  fieldset: {
                    borderColor: 'border.light',
                    borderRadius: theme.spacing(1),
                  },
                  height: '100%',
                },
                maxHeight: `calc(45vh - ${theme.spacing(14.5)})`,
              }}
              value={importJDText}
              onChange={handleJDImportTextFieldChange}
            />
          </Stack>
        )}
      </RadioGroup>
    </FormControl>
  );
};

export default SelectJobDescription;
