import type { ReactNode } from 'react';

import type { SxProps } from '@mui/system';

import theme from '../../../theme/theme';
import type { ButtonProps } from '../../common/Button';
import Button from '../../common/Button';
import Icon from '../../common/Icon';

interface ToolbarButtonProps extends ButtonProps {
  /**
   * The icon to display in the button. Can be a string (used as a className for the Icon component) or a ReactNode.
   */
  icon: ReactNode;

  /**
   * Inline styles for the icon inside the button.
   */
  iconStyle?: React.CSSProperties;

  /**
   * Custom styles for the button using Material-UI's `sx` prop.
   */
  sx?: SxProps;

  /**
   * If true, the button appears active with a distinct background color.
   */
  isActive?: boolean;
}

/**
 * ToolbarButton component is a reusable button element for toolbars.
 * It supports an icon, active state, and customization of styles.
 * It extends the common Button component with additional props.
 *
 * @param {string} variant - The variant of the button. Defaults to 'text'.
 * @param {boolean} disabled - If true, the button is disabled. Defaults to false.
 * @param {boolean} isActive - If true, the button appears active with a distinct background color. Defaults to false.
 * @param {SxProps} sx - Custom styles for the button using Material-UI's `sx` prop.
 * @param {ReactNode} icon - The icon to display in the button. Can be a string (used as a className for the Icon component) or a ReactNode.
 * @param {React.CSSProperties} iconStyle - Inline styles for the icon inside the button.
 * @param {ButtonProps} restProps - Additional props passed to the common Button component.
 *
 * @returns {JSX.Element} The rendered ToolbarButton component.
 *
 * @example
 * // Example usage:
 * // Example 1: Using an icon as a string
 * <ToolbarButton icon="fi fi-rr-bold" onClick={handleBoldClick} />
 *
 * @example
 * // Example 2: Using a custom ReactNode icon
 * <ToolbarButton icon={<CustomIcon />} isActive={true} onClick={handleCustomClick} />
 *
 * @example
 * // Example 3: Disabled button
 * <ToolbarButton icon="fi fi-rr-italic" disabled={true} />
 *
 * @example
 * // Example 4: Adding custom styles
 * <ToolbarButton
 *   icon="fi fi-rr-underline"
 *   sx={{ backgroundColor: 'lightblue' }}
 *   iconStyle={{ color: 'red' }}
 * />
 */
const ToolbarButton = ({
  variant = 'text',
  disabled = false,
  isActive = false,
  sx,
  icon,
  iconStyle,
  ...restProps
}: ToolbarButtonProps) => (
  <Button
    variant={variant}
    disabled={disabled}
    sx={{
      padding: 1.25,
      minWidth: 'fit-content',
      color: theme.palette.text.secondary,
      backgroundColor: isActive ? 'background.main' : '',
      '&.Mui-disabled': {
        backgroundColor: 'common.white',
        cursor: 'not-allowed',
      },
      '&:hover': { backgroundColor: 'background.main' },
      ...sx,
    }}
    startIcon={
      //  Render the icon. If the icon is a string, use the Icon component with className. Otherwise, render the ReactNode.
      typeof icon === 'string' ? (
        <Icon
          className={`format ${icon}`}
          style={{
            width: 16,
            height: 16,
            marginTop: 0,
            cursor: disabled ? 'not-allowed' : 'pointer', // Cursor style based on disabled state.
            ...iconStyle,
          }}
        />
      ) : (
        icon
      )
    }
    {...restProps}
  />
);

export default ToolbarButton;
