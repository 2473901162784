import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import theme from '../../../theme/theme';
import Button from '../../common/Button';
import Modal from '../../common/Modal/Modal';
import ModalActions from '../../common/Modal/ModalActions';
import ModalDetail from '../../common/Modal/ModalDetail';
import type { MissingFields } from '../constants';
import MissingJobInfoFormContent from './MissingJobInfoFormContent';

/**
 * Props for the MissingJobInfoForm component.
 */
interface MissingJobInfoFormProps {
  open: boolean;
  isLoading?: boolean;
  missingFields?: MissingFields[];
  onSubmitMissingInfo: SubmitHandler<FieldValues>;
}

/**
 * MissingJobInfoForm Component
 *
 * This component renders a form to collect missing information required for a job description.
 * It handles the form state, validation, and submission.
 *
 * @component
 *
 * @param {MissingJobInfoFormProps} props - The properties for the component.
 * @param {boolean} props.open - Indicates whether the form is open.
 * @param {() => void} props.onOpen - Callback function to handle the form opening.
 * @param {() => void} props.onClose - Callback function to handle the form closing.
 * @param {boolean} [props.isLoading] - Indicates whether the form submission is loading.
 * @param {MissingFields[]} [props.missingFields] - Array of missing fields to be filled in the form.
 * @param {SubmitHandler<FieldValues>} props.onSubmitMissingInfo - Callback function to handle the form submission.
 *
 * @returns {JSX.Element} The rendered MissingJobInfoForm component.
 *
 * @example
 * Example Usage:
 * <MissingJobInfoForm
 *   open={true}
 *   onOpen={handleOpen}
 *   onClose={handleClose}
 *   isLoading={false}
 *   missingFields={[{ name: 'jobTitle', label: 'Job Title', placeholder: 'Enter job title' }]}
 *   onSubmitMissingInfo={handleSubmit}
 * />
 *
 */
const MissingJobInfoForm = ({
  open,
  isLoading,
  missingFields,
  onSubmitMissingInfo,
}: MissingJobInfoFormProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();

  const { isMobileOrTablet } = useResponsiveDevice();
  return (
    <Modal
      open={open}
      contentSx={{
        ...(isMobileOrTablet
          ? {
              width: '100%',
              maxHeight: '90svh',
              alignSelf: 'end',
              padding: `${theme.spacing(3)} ${theme.spacing(1)}`,
              boxSizing: 'border-box',
              borderRadius: `${theme.spacing(1.5)} ${theme.spacing(1.5)} ${theme.spacing(
                0,
              )} ${theme.spacing(0)}`,
            }
          : { width: '31.25rem' }),
        gap: 4,
      }}
    >
      <>
        <ModalDetail
          title="Add Missing Job Information"
          description={
            <MissingJobInfoFormContent
              missingFields={missingFields}
              errors={errors}
              register={register}
            />
          }
        />
        <ModalActions
          sx={{ paddingX: 1 }}
          actions={
            <Button
              onClick={handleSubmit(onSubmitMissingInfo)}
              sx={{
                width: { xs: '100%', md: 'fit-content' },
                padding: {
                  xs: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
                  sm: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
                },
              }}
              disabled={!isValid}
              loading={isLoading}
            >
              Add
            </Button>
          }
        />
      </>
    </Modal>
  );
};

export default MissingJobInfoForm;
