import { isNotNil } from 'ramda';

import { Grid, Typography } from '@mui/material';
import type { SxProps } from '@mui/material';

import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import theme from '../../../theme/theme';
import Button from '../Button';
import { ButtonWithDisableTimer } from '../ButtonWithDisableTimer';
import Icon from '../Icon';

// TODO: Make this component more generic, rework how actions has displayed - by default it should be a Row not a column
interface ModalActionsProps {
  type?: 'confirm' | 'delete';
  disableConfirmButtonDuration?: number;
  isLoading?: boolean;
  actions?: React.ReactNode;
  onConfirm?: () => void;
  onClose?: () => void;
  sx?: SxProps;
}

/**
 * ModalActions component renders buttons or actions for confirmation or deletion in a modal.
 * It displays a "Cancel" button, and based on the action type, either a "Confirm" or "Delete" button.
 * The "Delete" button has a timer that disables it for a set duration.
 *
 * @component
 * @param {string} [type] - Type of modal action, either "confirm" or "delete". Determines the button styles and functionality.
 * @param {number} [disableConfirmButtonDuration] - Duration (in seconds) for which the "Confirm" or "Delete" button is disabled after being clicked. Default is 0 (no disable duration).
 * @param {boolean} [isLoading] - Indicates whether the buttons should be in a loading state.
 * @param {React.ReactNode} [actions] - Custom actions to display instead of the default "Cancel" and "Confirm/Delete" buttons.
 * @param {function} [onConfirm] - Callback function that is called when the "Confirm" or "Delete" button is clicked.
 * @param {function} [onClose] - Callback function that is called when the "Cancel" button is clicked.
 * @param {SxProps} [sx] - Custom styles for the container of the actions.
 *
 * @returns {JSX.Element} A styled container displaying action buttons or custom actions.
 *
 * @example
 * // Example usage:
 * <ModalActions
 *   type="delete"
 *   isLoading={false}
 *   onConfirm={() => console.log('Confirmed')}
 *   onClose={() => console.log('Closed')}
 *   sx={{ padding: 2 }}
 * />
 */
const ModalActions = ({
  type,
  disableConfirmButtonDuration,
  isLoading,
  actions,
  onClose,
  onConfirm,
  sx,
}: ModalActionsProps) => {
  // Determines if the device is mobile or not
  const { isMobile } = useResponsiveDevice();

  return (
    <Grid
      className="actions-grid-container"
      container
      sx={{ justifyContent: 'flex-end', gap: 1.5, ...sx }} // Apply custom styles and default grid gap
    >
      {isNotNil(actions) ? (
        // Render custom actions if provided
        <Grid item className="action-grid-item" sx={{ flexGrow: { xs: 1, sm: 0 } }}>
          {actions}
        </Grid>
      ) : (
        <>
          {/* Cancel button, which triggers the onClose callback */}
          <Grid className="action-grid-item" item sx={{ flexGrow: { xs: 1, sm: 0 } }}>
            <Button
              variant={type === 'delete' && !isMobile ? 'text' : 'outlined'}
              color={type === 'delete' ? 'inherit' : 'primary'}
              sx={{
                color: type === 'delete' ? 'text.primary' : 'primary.main',
                border: { xs: '1.5px solid', sm: type === 'delete' ? 'unset' : '1.5px solid' },
                borderColor: {
                  xs: 'border.light',
                  sm: type === 'delete' ? 'unset' : 'primary.main',
                },
                width: { xs: '100%', sm: 'fit-content' },
                padding: {
                  xs: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
                  sm: `${theme.spacing(1.25)} ${theme.spacing(1.5)}`,
                },
              }}
              onClick={onClose} // Calls onClose when Cancel button is clicked
            >
              {/* TODO: remove the Typography component, as it is already defined in the common button component */}
              <Typography variant="label2">Cancel</Typography>
            </Button>
          </Grid>

          {/* Confirm or Delete button, which triggers the onConfirm callback */}
          <Grid className="action-grid-item" item sx={{ flexGrow: { xs: 1, sm: 0 } }}>
            {type === 'delete' ? (
              // Render Delete button with a timer to disable it for a set duration
              <ButtonWithDisableTimer
                color="error"
                onClick={onConfirm} // Calls onConfirm when Delete button is clicked
                disableDuration={disableConfirmButtonDuration || 0}
                loading={isLoading} // Shows loading state when isLoading is true
                sx={{
                  width: { xs: '100%', sm: 'fit-content' },
                  padding: {
                    xs: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
                    sm: `${theme.spacing(1.25)} ${theme.spacing(1.5)}`,
                  },
                }}
              >
                {/* TODO: Use the button icon prop and remove the Typography component, as it is already defined in the common button component */}{' '}
                <Icon className="fi fi-rr-trash" /> {/* Trash icon for Delete */}
                <Typography variant="label2">Delete</Typography>
              </ButtonWithDisableTimer>
            ) : (
              // Render Confirm button with a timer to disable it for a set duration
              <ButtonWithDisableTimer
                color="primary"
                onClick={onConfirm} // Calls onConfirm when Confirm button is clicked
                disableDuration={disableConfirmButtonDuration || 0}
                loading={isLoading} // Shows loading state when isLoading is true
                sx={{
                  width: { xs: '100%', sm: 'fit-content' },
                  padding: {
                    xs: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
                    sm: `${theme.spacing(1.25)} ${theme.spacing(1.5)}`,
                  },
                }}
              >
                {/* TODO: remove the Typography component, as it is already defined in the common button component */}
                <Typography variant="label2">Confirm</Typography>
              </ButtonWithDisableTimer>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ModalActions;
