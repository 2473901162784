import type { ReactNode } from 'react';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import { CircularProgress, Stack, Typography, useTheme } from '@mui/material';

import { useSnackbar } from '../../../../contexts/snackbar';
import useResponsiveDevice from '../../../../hooks/useResponsiveDevice';
import type { ComposeFields } from '../../../../services/superEditor';
import {
  ComposeFieldTypes,
  generateAchievements,
  generateDailyRoutines,
  generateResponsibilities,
  generateStory,
  generateskillsAndTools,
} from '../../../../services/superEditor';
import BootstrapTooltip from '../../../common/BootstrapTooltip';
import Icon from '../../../common/Icon';
import InfoPopup from '../../../common/InfoPopup';
import TextFieldWithLabel from '../../../common/TextFieldWithLabel';
import ComposeActions from './ComposeActions';

interface ComposeWorkExpModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (fields: ComposeFields) => void;
}

export const ComposeWorkExpModal = ({ open, onClose, onSubmit }: ComposeWorkExpModalProps) => {
  const [step, setStep] = useState(1);
  const { isMobile, isDesktop } = useResponsiveDevice();

  const [loadingComposeField, setLoadingComposeField] = useState<ComposeFieldTypes>();

  const theme = useTheme();
  const { showSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
    watch,
    trigger,
    reset,
  } = useForm();

  const [jobPosition, skillsAndTools, responsibilities] = watch([
    ComposeFieldTypes.JOB_POSITION,
    ComposeFieldTypes.SKILLS_AND_TOOLS,
    ComposeFieldTypes.RESPONSIBILITIES,
  ]);

  useLayoutEffect(() => {
    // When the modal is opened, reset the form and set the step to 1
    if (open) {
      reset();
      setStep(1);
    }
  }, [open, reset]);

  const generateComposeField = useCallback(
    (field: ComposeFieldTypes) => async () => {
      setLoadingComposeField(field);

      switch (field) {
        case ComposeFieldTypes.SKILLS_AND_TOOLS:
          setLoadingComposeField(ComposeFieldTypes.SKILLS_AND_TOOLS);
          generateskillsAndTools({
            jobPosition: getValues(ComposeFieldTypes.JOB_POSITION) as string,
            onProgress: (data) => {
              setValue(ComposeFieldTypes.SKILLS_AND_TOOLS, data);
            },
            onEnd: (data) => {
              setValue(ComposeFieldTypes.SKILLS_AND_TOOLS, data);
              setLoadingComposeField(undefined);
            },
          }).catch((e) => {
            if (e.name !== 'AbortError') {
              showSnackbar('error', 'An error occurred while composing the Work Experience');
            }
            setLoadingComposeField(undefined);
          });
          break;

        case ComposeFieldTypes.RESPONSIBILITIES:
          setLoadingComposeField(ComposeFieldTypes.RESPONSIBILITIES);
          generateResponsibilities({
            jobPosition: getValues(ComposeFieldTypes.JOB_POSITION) as string,
            skillsAndTools: getValues(ComposeFieldTypes.SKILLS_AND_TOOLS) as string,
            onProgress: (data) => {
              setValue(ComposeFieldTypes.RESPONSIBILITIES, data);
            },
            onEnd: (data) => {
              setValue(ComposeFieldTypes.RESPONSIBILITIES, data);
              setLoadingComposeField(undefined);
              trigger();
            },
          }).catch((e) => {
            if (e.name !== 'AbortError') {
              showSnackbar('error', 'An error occurred while composing the Work Experience');
            }
            setLoadingComposeField(undefined);
          });
          break;

        case ComposeFieldTypes.ACHIEVEMENTS:
          setLoadingComposeField(ComposeFieldTypes.ACHIEVEMENTS);
          generateAchievements({
            jobPosition: getValues(ComposeFieldTypes.JOB_POSITION) as string,
            skillsAndTools: getValues(ComposeFieldTypes.SKILLS_AND_TOOLS) as string,
            responsibilities: getValues(ComposeFieldTypes.RESPONSIBILITIES) as string,
            onProgress: (data) => {
              setValue(ComposeFieldTypes.ACHIEVEMENTS, data);
            },
            onEnd: (data) => {
              setValue(ComposeFieldTypes.ACHIEVEMENTS, data);
              setLoadingComposeField(undefined);
            },
          }).catch((e) => {
            if (e.name !== 'AbortError') {
              showSnackbar('error', 'An error occurred while composing the Work Experience');
            }
            setLoadingComposeField(undefined);
          });
          break;

        case ComposeFieldTypes.DAILY_ROUTINES:
          setLoadingComposeField(ComposeFieldTypes.DAILY_ROUTINES);
          generateDailyRoutines({
            jobPosition: getValues(ComposeFieldTypes.JOB_POSITION) as string,
            skillsAndTools: getValues(ComposeFieldTypes.SKILLS_AND_TOOLS) as string,
            responsibilities: getValues(ComposeFieldTypes.RESPONSIBILITIES) as string,
            achievements: getValues(ComposeFieldTypes.ACHIEVEMENTS) as string,
            onProgress: (data) => {
              setValue(ComposeFieldTypes.DAILY_ROUTINES, data);
            },
            onEnd: (data) => {
              setValue(ComposeFieldTypes.DAILY_ROUTINES, data);
              setLoadingComposeField(undefined);
            },
          }).catch((e) => {
            if (e.name !== 'AbortError') {
              showSnackbar('error', 'An error occurred while composing the Work Experience');
            }
            setLoadingComposeField(undefined);
          });
          break;

        case ComposeFieldTypes.STORY:
          setLoadingComposeField(ComposeFieldTypes.STORY);
          generateStory({
            jobPosition: getValues(ComposeFieldTypes.JOB_POSITION) as string,
            skillsAndTools: getValues(ComposeFieldTypes.SKILLS_AND_TOOLS) as string,
            responsibilities: getValues(ComposeFieldTypes.RESPONSIBILITIES) as string,
            achievements: getValues(ComposeFieldTypes.ACHIEVEMENTS) as string,
            dailyRoutines: getValues(ComposeFieldTypes.DAILY_ROUTINES) as string,
            onProgress: (data) => {
              setValue(ComposeFieldTypes.STORY, data);
            },
            onEnd: (data) => {
              setValue(ComposeFieldTypes.STORY, data);
              setLoadingComposeField(undefined);
            },
          }).catch((e) => {
            if (e.name !== 'AbortError') {
              showSnackbar('error', 'An error occurred while composing the Work Experience');
            }
            setLoadingComposeField(undefined);
          });
          break;

        default:
          break;
      }
    },
    [getValues, setValue, showSnackbar, trigger],
  );

  const shouldDisableEndAdornment = useMemo(
    () => ({
      [ComposeFieldTypes.JOB_POSITION]: !jobPosition,
      [ComposeFieldTypes.SKILLS_AND_TOOLS]: !jobPosition,
      [ComposeFieldTypes.RESPONSIBILITIES]: !jobPosition || !skillsAndTools,
      [ComposeFieldTypes.ACHIEVEMENTS]: !jobPosition || !skillsAndTools || !responsibilities,
      [ComposeFieldTypes.DAILY_ROUTINES]: !jobPosition || !skillsAndTools || !responsibilities,
      [ComposeFieldTypes.STORY]: !jobPosition || !skillsAndTools || !responsibilities,
    }),
    [jobPosition, skillsAndTools, responsibilities],
  );

  const getEndAdornment = useCallback(
    (fieldType: ComposeFieldTypes) => {
      const disable = shouldDisableEndAdornment[fieldType];
      return loadingComposeField === fieldType ? (
        <CircularProgress
          size={16}
          value={50}
          variant="indeterminate"
          sx={{
            padding: 1.25,
            color: theme.palette.text.secondary,
          }}
        />
      ) : (
        <BootstrapTooltip
          title="Generate Skills & Tools"
          placement="bottom"
          PopperProps={{
            sx: {
              marginTop: '-0.5rem',
            },
          }}
        >
          <Icon
            className="fi fi-rr-magic-wand"
            style={{
              fontSize: theme.spacing(2),
              color: theme.palette.text.tertiary,
              display: disable ? 'none' : undefined,
              pointerEvents: disable ? 'none' : 'auto',
              padding: theme.spacing(1.25),
              maxHeight: theme.spacing(5),
              boxSizing: 'border-box',
            }}
            onClick={generateComposeField(fieldType)}
          />
        </BootstrapTooltip>
      );
    },
    [generateComposeField, loadingComposeField, shouldDisableEndAdornment, theme],
  );

  const handleSubmitButtonClick = (e?: React.BaseSyntheticEvent) => {
    e?.stopPropagation();
    handleSubmit((fields: FieldValues) => {
      if (step === 1) {
        setStep(2);
      } else {
        onSubmit(fields as ComposeFields);
      }
    })();
  };

  const handleCancelButtonClick = () => {
    if (step === 1) {
      onClose();
    } else {
      setStep(1);
    }
  };

  // TODO: move to a separate form component
  const ComposeForm: ReactNode = useMemo(
    () => (
      <Stack gap={5}>
        <Typography variant="body3">
          AI will write 6 resume bullet points based on your answers. Answer freely in any format.
          Phrases, fragments, or even scribbles are perfectly fine.
        </Typography>
        <form
          style={{
            // animate height change
            transition: 'height 0.5s ease',
          }}
        >
          <Stack sx={{ overflowX: 'hidden', gap: 2.5 }}>
            {step === 1 && (
              <>
                <TextFieldWithLabel
                  id={ComposeFieldTypes.JOB_POSITION}
                  label="Your detailed job position"
                  placeholder="UX designer | E-Commerce | SaaS"
                  required
                  autoFocus
                  errors={errors}
                  {...register('jobPosition', {
                    required: 'Job Position is required',
                    value: '',
                  })}
                />

                <TextFieldWithLabel
                  id={ComposeFieldTypes.SKILLS_AND_TOOLS}
                  label="Skills & tools"
                  placeholder={
                    isDesktop
                      ? 'User research, information architecture, workflow design, wireframing, user onboarding, figma, adobe illustrator'
                      : 'User research, information architecture, workflow design, wireframing, Figma'
                  }
                  required
                  errors={errors}
                  {...register(ComposeFieldTypes.SKILLS_AND_TOOLS, {
                    required: 'Skills and Tools are required',
                    value: '',
                  })}
                  InputProps={{
                    endAdornment: getEndAdornment(ComposeFieldTypes.SKILLS_AND_TOOLS),
                  }}
                  sx={{ '& .MuiInputBase-root': { alignItems: 'flex-start', padding: 0 } }}
                  fullWidth
                  multiline={isMobile} // Condition applied directly
                  minRows={isMobile ? 5 : undefined} // Set undefined when not needed
                  maxRows={isMobile ? 5 : undefined} // Set undefined when not needed
                />

                <TextFieldWithLabel
                  id={ComposeFieldTypes.RESPONSIBILITIES}
                  label="Responsibilities"
                  errors={errors}
                  required
                  placeholder={
                    isDesktop
                      ? 'Creating user journeys and task flows, rapid prototyping, design consistency, working closely with product manager and engineering team for execution'
                      : 'Creating user journeys and task flows, rapid prototyping, design consistency...'
                  }
                  {...register('responsibilities', {
                    required: 'Responsibilites are required',
                    value: '',
                  })}
                  InputProps={{
                    endAdornment: getEndAdornment(ComposeFieldTypes.RESPONSIBILITIES),
                  }}
                  sx={{ '& .MuiInputBase-root': { alignItems: 'flex-start', padding: 0 } }}
                  fullWidth
                  multiline
                  minRows={isMobile ? 5 : 3}
                  maxRows={5}
                />
              </>
            )}
            {step === 2 && (
              <>
                <TextFieldWithLabel
                  id={ComposeFieldTypes.ACHIEVEMENTS}
                  label="Achievements or recognitions"
                  placeholder="Redesigned E-Commerce navigation and checkout process, boosting conversions by 30%"
                  showOptionalLabel
                  errors={errors}
                  {...register(ComposeFieldTypes.ACHIEVEMENTS, { value: '' })}
                  InputProps={{
                    endAdornment: getEndAdornment(ComposeFieldTypes.ACHIEVEMENTS),
                  }}
                  sx={{ '& .MuiInputBase-root': { alignItems: 'flex-start', padding: 0 } }}
                  fullWidth
                  autoFocus
                  multiline
                  maxRows={5}
                />

                <TextFieldWithLabel
                  id={ComposeFieldTypes.DAILY_ROUTINES}
                  label="Daily routines"
                  placeholder={
                    isDesktop
                      ? 'User research, creating wireframes and prototypes with figma, conducting usability tests, collaborating with the product manager and dev team, iterating on designs, reviewing user feedback, and ensuring design consistency across the platform.'
                      : 'User research, creating wireframes and prototypes with Figma, conducting usability tests, collaborating...'
                  }
                  showOptionalLabel
                  errors={errors}
                  {...register(ComposeFieldTypes.DAILY_ROUTINES, { value: '' })}
                  InputProps={{
                    endAdornment: getEndAdornment(ComposeFieldTypes.DAILY_ROUTINES),
                  }}
                  sx={{ '& .MuiInputBase-root': { alignItems: 'flex-start', padding: 0 } }}
                  fullWidth
                  multiline
                  minRows={4}
                  maxRows={5}
                />

                <TextFieldWithLabel
                  id={ComposeFieldTypes.STORY}
                  label="Your story to share"
                  placeholder={
                    isDesktop
                      ? 'Faced with a complex e-commerce redesign, I tackled navigation issues and a confusing checkout process. I led a user-centered approach, gathering feedback through testing. Iterating rapidly with Figma, I collaborated with engineers to streamline navigation and simplify checkout, boosting conversions by 30%.'
                      : 'Faced with a complex e-commerce redesign, I tackled navigation issues and a confusing checkout process...'
                  }
                  showOptionalLabel
                  errors={errors}
                  {...register(ComposeFieldTypes.STORY, { value: '' })}
                  InputProps={{
                    endAdornment: getEndAdornment(ComposeFieldTypes.STORY),
                  }}
                  sx={{ '& .MuiInputBase-root': { alignItems: 'flex-start', padding: 0 } }}
                  fullWidth
                  multiline
                  minRows={3}
                  maxRows={5}
                />
              </>
            )}
          </Stack>
        </form>
      </Stack>
    ),
    [errors, getEndAdornment, isDesktop, isMobile, register, step],
  );

  return (
    <InfoPopup
      drawerButtonType="close"
      content={{ title: 'Compose', description: ComposeForm }}
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      sx={{
        '& .mobile-drawer-description': { maxHeight: '70vh' },
        '& .desktop-modal-content': {
          width: '70vw',
          maxWidth: '60rem',
        },
        '& .modal-detail': { gap: 2 },
        '& .action-grid-item': {
          flexGrow: 1,
        },
        '& .actions-grid-container': {
          paddingX: 1,
        },
      }}
      actions={
        <ComposeActions
          step={step}
          errors={errors}
          isValid={isValid}
          handleCancelButtonClick={handleCancelButtonClick}
          handleSubmitButtonClick={handleSubmitButtonClick}
        />
      }
    />
  );
};
