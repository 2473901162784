import { useLayoutEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import type { AlertColor } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import AddMissingInfoButton from '../../components/common/AddMissingInfoButton';
import Button from '../../components/common/Button';
import Icon from '../../components/common/Icon';
import { useManageSubscriptionModal } from '../../contexts/ManageSubscriptionModal/ManageSubscriptionModal';
import { useSnackbar } from '../../contexts/snackbar';
import useResponsiveDevice from '../../hooks/useResponsiveDevice';
import { ResponseStatus } from '../../interface';
import RoutePaths from '../../routes/RoutePaths';
import { EventAction, EventCategory, logEvent } from '../../services/analytics';
import {
  JobDescriptionTypes,
  JobProgressSource,
  getAllJobXRays,
} from '../../services/jobDescriptions';
import { Storage, StorageKeys } from '../../services/storage';
import { useAccountStatusStore } from '../../stores/AccountStatusStore';
import { useAppBarStore } from '../../stores/AppBarStore';
import { useLastVisitedTopPageStore } from '../../stores/LastVisitedTopPageStore';
import theme from '../../theme/theme';
import { getListingSnackbar } from '../../utils';
import JobXraysList from './components/JobXraysList';
import JobXraysTable from './components/JobXraysTable';

const snackbarMessage = {
  missing: 'Job X-Ray is missing some required information',
  in_progress: 'New Job Description is being created',
  error: 'New Job Description could not be imported',
  success: 'Job Description created successfully',
};

const JobXrays = () => {
  const navigate = useNavigate();
  const { showSnackbar, hideSnackbar } = useSnackbar();
  const { setLastVisitedTopPage } = useLastVisitedTopPageStore();
  const { isMobile, isTablet } = useResponsiveDevice();
  const { setAppBar, resetToDesktopAppBar, setInitialState } = useAppBarStore();
  const [isNavigatedFromJobProgressPage, setIsNavigatedFromJobProgressPage] = useState(false);
  const { isJDImportEnabled } = useAccountStatusStore();
  const { openModal: openManageSubscriptionModal } = useManageSubscriptionModal();

  const CreateJobXrayButton = useMemo(() => {
    const onCreateButtonClick = () => {
      if (isJDImportEnabled) {
        navigate(RoutePaths.CREATE_JOB);
      } else {
        openManageSubscriptionModal();
      }
    };

    return (
      <Button
        variant="text"
        sx={{
          padding: 1.5,
          minWidth: 'max-content',
          ...(!isJDImportEnabled && {
            backgroundColor: 'background.mediumLight',
            ':hover': {
              borderWidth: 0,
              backgroundColor: 'background.main',
            },
          }),
        }}
        onClick={onCreateButtonClick}
      >
        <Icon
          className={`fi ${isJDImportEnabled ? 'fi-rr-plus' : 'fi-rr-lock'}`}
          style={{
            fontSize: '20px',
            ...(!isJDImportEnabled && { color: theme.palette.text.secondary }),
          }}
        />
      </Button>
    );
  }, [isJDImportEnabled, navigate, openManageSubscriptionModal]);

  const jobXraysQueryHandler = async () => {
    const res = await getAllJobXRays();

    const listingSnackbar = getListingSnackbar(
      res?.status?.status,
      RoutePaths.JOBS,
      isNavigatedFromJobProgressPage,
      setIsNavigatedFromJobProgressPage,
      snackbarMessage,
    );

    if (listingSnackbar) {
      if (listingSnackbar?.showControl) {
        const missingJobXrayId = res?.result.find(
          (jobXray) => jobXray.status === ResponseStatus.MISSING,
        )?._id;
        const missingInfoButton = (
          <AddMissingInfoButton
            onClick={() => {
              hideSnackbar();
              navigate(RoutePaths.CREATE_JOB, {
                state: { jobDescriptionId: missingJobXrayId },
              });
            }}
            sx={{ marginLeft: { xs: 0, md: 2.5 }, color: 'common.white' }}
            typographyVariant="label2"
            hideIcon
          />
        );
        showSnackbar(
          listingSnackbar.severity as AlertColor,
          listingSnackbar.message,
          missingInfoButton,
        );
      } else {
        logEvent(EventCategory.FORM_SUBMISSION, EventAction.SUBMIT, listingSnackbar.message);
        showSnackbar(listingSnackbar.severity as AlertColor, listingSnackbar.message);
      }
    }

    return res;
  };

  const { data: jobXraysQueryResponse, isLoading } = useQuery({
    queryKey: [JobDescriptionTypes.JOBXRAYS],
    queryFn: jobXraysQueryHandler,
    refetchInterval: (data) => {
      // if job descriptions creation is in progress, refetch every 5 seconds
      if (data?.status?.status === ResponseStatus.IN_PROGRESS) {
        return 5000;
      }
      return false;
    },
  });

  // job descriptions data from api response
  const jobXrays = jobXraysQueryResponse?.result;

  useLayoutEffect(() => {
    setLastVisitedTopPage(RoutePaths.JOBS);
    setIsNavigatedFromJobProgressPage(
      Storage.get(StorageKeys.CONTINUE_TO_JOQBQUEST_URL) === RoutePaths.JOBS,
    );
    setInitialState(isMobile);
  }, [isMobile, setInitialState, setLastVisitedTopPage]);

  useLayoutEffect(() => {
    if (isMobile || isTablet) {
      setAppBar('Jobs', jobXrays && jobXrays?.length > 0 ? CreateJobXrayButton : null);
    } else {
      resetToDesktopAppBar();
    }
    return () => {
      setInitialState(isMobile);
    };
  }, [
    CreateJobXrayButton,
    isMobile,
    isTablet,
    jobXrays,
    resetToDesktopAppBar,
    setAppBar,
    setInitialState,
  ]);

  // Missing info job xray listing page handler
  const onClick = (
    id: string,
    status: ResponseStatus,
    source: JobProgressSource,
    sourceId: string,
  ) => {
    if (id && status === ResponseStatus.DONE) {
      navigate(`${RoutePaths.JOBS}/${id}`);
    } else if (sourceId && status === ResponseStatus.MISSING) {
      switch (source) {
        case JobProgressSource.AI_CUSTOMIZE:
          navigate(RoutePaths.AI_CUSTOMIZED.replace(':id', sourceId), {
            state: { aiCustomizeId: sourceId },
          });
          break;
        case JobProgressSource.MATCH_SCORE:
          navigate(RoutePaths.CREATE_JOB_MATCH, { state: { matchScoreId: sourceId } });
          break;
        case JobProgressSource.JOB_XRAY:
          navigate(RoutePaths.CREATE_JOB, { state: { jobDescriptionId: sourceId } });
          break;
        case JobProgressSource.AI_GENERATE:
          navigate(RoutePaths.RESUME_SAMPLE, { state: { resumeId: sourceId } });
          break;
        default:
          break;
      }
    }
  };

  return isMobile || isTablet ? (
    <JobXraysList jobXrays={jobXrays} isLoading={isLoading} onClick={onClick} />
  ) : (
    <JobXraysTable jobXrays={jobXrays} isLoading={isLoading} onClick={onClick} />
  );
};

export default JobXrays;
