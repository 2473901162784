import type { SxProps } from '@mui/material';
import { Box, Stack, Typography } from '@mui/material';

import theme from '../../../theme/theme';
import Icon from '../Icon';

interface ModalDetailProps {
  title: string;
  subtitle?: string;
  description?: string | React.ReactNode;
  children?: React.ReactNode;
  onClose?: () => void;
  sx?: SxProps;
}

/**
 * ModalDetail component displays a modal header with a title, optional subtitle, and optional description.
 * It also provides the ability to render additional content (children) and an optional close icon.
 *
 * @component
 * @param {string} title - The main title text for the modal, displayed prominently.
 * @param {string} [subtitle] - An optional subtitle, displayed below the title.
 * @param {string | React.ReactNode} [description] - Optional description content for the modal, displayed below the title and subtitle.
 * @param {React.ReactNode} [children] - Additional content to be rendered at the bottom of the modal.
 * @param {function} [onClose] - Callback function triggered when the close icon is clicked. If not provided, the close icon is not displayed.
 * @param {SxProps} [sx] - Custom styles applied to the root container of the component.
 *
 * @returns {JSX.Element} A styled modal detail component with title, subtitle, description, and optional close functionality.
 *
 * @example
 * // Example usage:
 * <ModalDetail
 *   title="Modal Title"
 *   subtitle="This is a subtitle"
 *   description="This is the description for the modal."
 *   onClose={() => console.log('Modal closed')}
 *   sx={{ backgroundColor: 'white' }}
 * >
 *   <div>Additional content goes here</div>
 * </ModalDetail>
 */
const ModalDetail = ({ title, subtitle, description, children, sx, onClose }: ModalDetailProps) => (
  <Stack
    className="modal-detail"
    sx={{ gap: 3, paddingX: 1, overflowY: 'auto', ...sx }} // Apply custom and default styles.
  >
    {/* Title and optional subtitle section */}
    <Stack direction="row" justifyContent="space-between">
      <Stack>
        <Typography id="modal-title" variant="h3">
          {title}
        </Typography>
        {subtitle && (
          <Typography id="modal-subtitle" variant="body3">
            {subtitle}
          </Typography>
        )}
      </Stack>

      {/* Close icon, displayed only if onClose is provided */}
      {onClose && (
        <Stack pt={0.5} id="modal-icon">
          <Icon
            className="fi fi-rr-cross-small"
            style={{ color: theme.palette.text.secondary }}
            onClick={onClose}
          />
        </Stack>
      )}
    </Stack>

    {/* Optional description section */}
    {description && (
      <Typography id="modal-description" variant="body3">
        {description}
      </Typography>
    )}

    {/* Render additional children content, if provided */}
    {children && <Box className="modal-children">{children}</Box>}
  </Stack>
);

export default ModalDetail;
