import type { LexicalEditor } from 'lexical';
import { $createParagraphNode, $createTextNode, $getRoot } from 'lexical';
import { useCallback, useMemo, useRef, useState } from 'react';
import uuid from 'react-uuid';

import { FormHelperText, InputLabel, Stack, Typography } from '@mui/material';

import { useManageSubscriptionModal } from '../../../contexts/ManageSubscriptionModal/ManageSubscriptionModal';
import { useSnackbar } from '../../../contexts/snackbar';
import { useAbortSignal } from '../../../hooks/useAbortSignal';
import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import type { ObjectiveInfo } from '../../../interface';
import { EventAction, EventCategory, logEvent } from '../../../services/analytics';
import { PlanTypes } from '../../../services/billing';
import { autofixObjective } from '../../../services/superEditor';
import { useAccountStatusStore } from '../../../stores/AccountStatusStore';
import type { Draft } from '../../../stores/SuperEditorDrafts';
import useDraftsStore, { DraftType } from '../../../stores/SuperEditorDrafts';
import { getDraftTooltipText, getDraftsIcon, getTextFromHtml } from '../../../utils';
import FormActions from '../../Form/FormActions';
import RichTextEditor from '../../RichTextEditor/RichTextEditor';
import type { ToolbarExternalControl } from '../../RichTextEditor/plugins/ToolbarPlugin';
import DefaultDisabledTooltipContent from '../../common/DefaultDisabledTooltipContent';
import HelperTextWithModal from '../../common/HelperTextWithModal';
import DraftsManager from './Drafts/DraftsManager';

interface ObjectiveEditorProps {
  onEdit: (objective: ObjectiveInfo) => void;
  defaultObjective?: ObjectiveInfo;
}

const ObjectiveEditor = ({ onEdit, defaultObjective }: ObjectiveEditorProps) => {
  const [message, setMessage] = useState(defaultObjective?.subtext || '');

  const richTextEditorRef = useRef<LexicalEditor>();
  const { drafts, maxDrafts, isLoading, addDraft, updateDraft, getDraft, removeDraft } =
    useDraftsStore();
  const { showSnackbar } = useSnackbar();
  const { isMobileOrTablet } = useResponsiveDevice();
  const { openModal: openManageSubscriptionModal } = useManageSubscriptionModal();

  const { abortSignal } = useAbortSignal();
  const { isAutofixEnabled, accountStatus } = useAccountStatusStore();

  const onRichTextEditorRef = (ref: LexicalEditor) => {
    richTextEditorRef.current = ref;
  };

  const onAppendDraft = (draft: Draft) => {
    // TODO: think about moving these to the rich text editor, might be a better idea to explore editor Commands
    if (richTextEditorRef.current) {
      // append the draft text to the editor content
      richTextEditorRef.current.update(() => {
        const root = $getRoot();
        const paragraph = $createParagraphNode();
        const element = $createTextNode(draft.text);
        paragraph.append(element);

        root.append(paragraph);
        root.selectEnd();
      });
    }
  };

  const onReplaceDraft = (draft: Draft) => {
    // TODO: think about moving these to the rich text editor, might be a better idea to explore editor Commands
    if (richTextEditorRef.current) {
      // replace the editor content with the draft text
      richTextEditorRef.current.update(() => {
        const root = $getRoot();
        const paragraph = $createParagraphNode();
        const element = $createTextNode(draft.text);
        paragraph.append(element);

        root.clear();
        root.append(paragraph);
        root.selectEnd();
      });
    }
  };

  const onAutofixClick = useCallback(() => {
    const id = uuid();

    // add empty draft
    addDraft({
      id,
      text: '',
      type: DraftType.AUTOFIX,
      status: 'loading',
    });

    autofixObjective({
      text: getTextFromHtml(message),
      onProgress: (data) => {
        // add slight delay
        setTimeout(() => {
          addDraft({
            id,
            text: data,
            type: DraftType.AUTOFIX,
            status: 'loading',
          });
        }, 500);
      },
      onEnd: (data) => {
        // adding an artificial delay, because for small texts its too fast
        setTimeout(() => {
          logEvent(EventCategory.USER_INTERACTION, EventAction.CLICK, 'Objective Autofixed');
          updateDraft(id, {
            id,
            text: data,
            type: DraftType.AUTOFIX,
            status: 'success',
          });
        }, 1000);
      },
      signal: abortSignal,
    }).catch((e: Error) => {
      if (e.name !== 'AbortError') {
        showSnackbar(
          'error',
          e?.message || 'Failed to generate autofix draft. Please try again later.',
        );
        const draft = getDraft(id);
        if (draft?.text === '') {
          removeDraft(id);
        } else {
          updateDraft(id, {
            type: DraftType.AUTOFIX,
            status: 'error',
          });
        }
      }
    });
  }, [addDraft, message, abortSignal, updateDraft, showSnackbar, getDraft, removeDraft]);

  const toolbarExternalControls = useMemo<ToolbarExternalControl[]>(() => {
    // Enable autofix button if description has more than 3 words
    const shouldDisable =
      isLoading ||
      !isAutofixEnabled ||
      drafts.length === maxDrafts ||
      getTextFromHtml(message).trim().split(' ').length < 3;

    return [
      {
        title: 'Autofix',
        icon: getDraftsIcon(DraftType.AUTOFIX, !isAutofixEnabled),
        disabled: shouldDisable,
        tooltipText: getDraftTooltipText(
          DraftType.AUTOFIX,
          'Objective',
          shouldDisable,
          drafts.length === maxDrafts,
          maxDrafts,
          !isAutofixEnabled ? <DefaultDisabledTooltipContent /> : undefined,
        ),
        onClick: onAutofixClick,
        onDisabledItemClick: () => {
          if (isMobileOrTablet && accountStatus?.subscription === PlanTypes.STANDARD) {
            openManageSubscriptionModal();
          }
        },
      },
    ];
  }, [
    accountStatus?.subscription,
    drafts.length,
    isAutofixEnabled,
    isLoading,
    isMobileOrTablet,
    maxDrafts,
    message,
    onAutofixClick,
    openManageSubscriptionModal,
  ]);

  // Define a function to handle the form submission
  const onSubmit = () => {
    // Call the callback function to update the objective
    onEdit({
      _id: defaultObjective?._id || '',
      subtext: message,
    });
  };

  const onEditorChange = (html: string) => {
    setMessage(html);
  };

  return (
    // TODO: refactor to make this a generic component for use-cases like hero, objective, professional summary, etc.
    <form onSubmit={(e) => e.preventDefault()}>
      <Stack gap="48px">
        <Stack gap="8px">
          <Stack gap="4px">
            <InputLabel
              htmlFor="objective"
              sx={{
                maxHeight: '24px',
                '& .MuiFormLabel-asterisk': {
                  color: 'error.main',
                  paddingLeft: 0.5,
                  fontSize: '12px',
                },
              }}
              required
            >
              <Typography variant="label2" color="text.primary">
                Objective
              </Typography>
            </InputLabel>
            <FormHelperText>
              <Typography variant="body3">
                Describe your immediate career goal and core competency.
                <HelperTextWithModal
                  modalTitle="Example Objectives"
                  buttonText="Example"
                  modalContent={
                    <Stack gap={1}>
                      <Typography variant="label2" sx={{ fontWeight: 700 }}>
                        Recent graduate
                      </Typography>
                      <Typography variant="body3">
                        Motivated Journalism graduate seeking an entry-level copywriter position to
                        utilize my strong writing skills. Committed to delivering high-quality
                        articles and engaging with target audiences to effectively convey brand
                        messaging.
                      </Typography>
                      <Typography variant="label2" sx={{ fontWeight: 700 }}>
                        Career transition from Sales to IT support
                      </Typography>
                      <Typography variant="body3">
                        Seeking a tech support role in IT, leveraging proven soft skills honed in
                        sales and Cisco Certified Network Associate credentials.
                      </Typography>
                    </Stack>
                  }
                />
              </Typography>
            </FormHelperText>
          </Stack>
          <RichTextEditor
            html={defaultObjective?.subtext || ''}
            toolbarExternalControls={toolbarExternalControls}
            onEditorRefChange={onRichTextEditorRef}
            onChange={onEditorChange}
            autoFocus
            disabled={isLoading}
          />
        </Stack>

        <DraftsManager
          sectionTitle="Objective"
          onAppendDraft={onAppendDraft}
          onReplaceDraft={onReplaceDraft}
        />

        {/* TODO: pass isLoading */}
        <FormActions onSubmit={onSubmit} />
      </Stack>
    </form>
  );
};

export default ObjectiveEditor;
