import { Typography } from '@mui/material';

import { ResponseStatus } from '../../interface';
import Icon from './Icon';
import ScanProgress from './ScanProgress';

interface ProgressStatusProps {
  progress: number;
  status: string;
  onSuccess?: JSX.Element;
  onPending?: JSX.Element;
}

const ProgressStatus = ({ progress, status, onSuccess, onPending }: ProgressStatusProps) => {
  switch (status) {
    case ResponseStatus.TODO:
    case ResponseStatus.IN_PROGRESS:
      return <ScanProgress progress={progress} />;

    case ResponseStatus.ERROR:
      return (
        <Typography
          variant="label1"
          sx={{
            display: 'flex',
            gap: 1,
            color: 'error.main',
          }}
        >
          <Icon fontSize="20px" className="fi fi-br-exclamation" />
          Failed
        </Typography>
      );

    case ResponseStatus.PENDING:
    case ResponseStatus.MISSING:
    case ResponseStatus.SELECT_KEYWORDS:
      return (
        onPending || (
          <Typography
            variant="label1"
            sx={{
              display: 'flex',
              gap: 1,
              color: 'error.main',
            }}
          >
            <Icon fontSize="20px" className="fi fi-br-shield-exclamation" />
            Add Missing Info
          </Typography>
        )
      );

    case ResponseStatus.DONE:
      return onSuccess || null;

    default:
      return null;
  }
};

export default ProgressStatus;
