import type { ChangeEvent } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Typography, useTheme } from '@mui/material';

import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import type { JobDescriptionXRayListResult } from '../../../services/jobDescriptions';
import Button from '../../common/Button';
import Card from '../../common/Card';
import SwipeableDrawer, { Puller } from '../../common/SwipeableDrawer';
import SelectJobXrayContent from './SelectJobXrayContent';

export interface SelectJobXrayProps {
  jobDescriptionList: JobDescriptionXRayListResult[] | undefined;
  onSelectJD: ({ jdId }: { jdId: string }) => void;
  onClose?: () => void;
  isLoading?: boolean;
  defaultValue?: string;
  isSectionEnabled?: boolean;
}

/**
 * A component that allows users to select a job description from a list of available options.
 * It adapts its layout based on the device type, showing a card for desktop view
 * and a swipeable drawer for mobile devices. Ideal for scenarios where users need to pick
 * a job description (job x-ray) in a responsive manner.
 *
 * @component
 *
 * @param {Object} props - The properties for SelectJobXray component
 * @param {JobDescriptionXRayListResult[] | undefined} props.jobDescriptionList - The list of job descriptions available for selection.
 * @param {(params: { jdId: string }) => void} props.onSelectJD - Callback function invoked when a job description is selected, receiving the selected job description ID.
 * @param {() => void} [props.onClose] - Optional callback triggered when the swipeable drawer is closed.
 * @param {boolean} [props.isLoading=false] - Flag to indicate if the job descriptions are still loading. Defaults to `false`.
 * @param {string} [props.defaultValue=""] - Default selected job description ID.
 * @param {boolean} [props.isSectionEnabled=false] - Flag that controls if the section is enabled and visible. Defaults to `false`.
 *
 * @returns {JSX.Element | null} The rendered JSX element, or `null` if `isSectionEnabled` is `false`.
 *
 * @example
 * const jobDescriptions = [
 *   { id: 'jd1', title: 'Frontend Developer' },
 *   { id: 'jd2', title: 'Backend Developer' },
 * ];
 *
 * const handleSelectJD = ({ jdId }: { jdId: string }) => {
 *   console.log(`Selected Job Description ID: ${jdId}`);
 * };
 *
 * <SelectJobXray
 *   jobDescriptionList={jobDescriptions}
 *   onSelectJD={handleSelectJD}
 *   isLoading={false}
 *   defaultValue="jd1"
 *   isSectionEnabled={true}
 * />;
 */

const SelectJobXray = ({
  jobDescriptionList,
  onSelectJD,
  onClose,
  isLoading = false,
  defaultValue = '',
  isSectionEnabled = false,
}: SelectJobXrayProps) => {
  const theme = useTheme();
  const { isMobile } = useResponsiveDevice();
  const [open, setOpen] = useState<boolean>(isSectionEnabled);
  const [jobDescriptionId, setJobDescriptionId] = useState(defaultValue);

  useEffect(() => {
    if (isMobile) {
      setOpen(isSectionEnabled);
    }
  }, [isMobile, isSectionEnabled]);

  const onCloseDrawer = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const onSelect = () => {
    onSelectJD({ jdId: jobDescriptionId });
    setOpen(false);
  };

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!isMobile) {
        onSelectJD({ jdId: event.target.value });
      }
      setJobDescriptionId(event.target.value);
    },
    [isMobile, onSelectJD],
  );

  const children = useMemo(
    () =>
      SelectJobXrayContent({
        isLoading,
        jobDescriptionList,
        jobDescriptionId,
        onChange,
        defaultValue,
      }),
    [isLoading, jobDescriptionList, jobDescriptionId, onChange, defaultValue],
  );

  // Render Card for desktop version and SwipeableDrawer for mobile version
  return isSectionEnabled ? (
    <>
      {!isMobile ? (
        <Card
          sx={{
            height: '45vh',
            boxSizing: 'border-box',
            overflowY: 'scroll',
          }}
          cardContentProps={{
            sx: {
              padding: `${theme.spacing(1)} ${theme.spacing(2)}
                            ${theme.spacing(1)} ${theme.spacing(4)}`,
              height: '100%',
              boxSizing: 'border-box',
            },
          }}
        >
          {children}
        </Card>
      ) : (
        <SwipeableDrawer
          open={open}
          onClose={onCloseDrawer}
          onOpen={() => setOpen(true)}
          contentProps={{
            sx: {
              maxWidth: '100vw',
              height: '85vh',
              textAlign: 'left',
              paddingBottom: 3,
              boxSizing: 'border-box',
            },
          }}
        >
          <Puller />
          <Typography variant="h3" sx={{ width: '100%', paddingTop: 3, paddingBottom: 2 }}>
            Selecting Job X-Ray
          </Typography>
          <Box
            sx={{
              width: '100%',
              height: `calc(85vh - ${theme.spacing(20)})`,
              overflowY: 'scroll',
              overflowX: 'hidden',
            }}
          >
            {children}
          </Box>
          <Button sx={{ width: '100%', padding: 2, marginTop: 1 }} onClick={onSelect}>
            Select
          </Button>
        </SwipeableDrawer>
      )}
    </>
  ) : null;
};

export default SelectJobXray;
