import type { FieldErrors, UseFormRegister } from 'react-hook-form';

import { Stack } from '@mui/material';

import InputLabel from '../../common/InputLabel';
import TextField from '../../common/TextField';
import type { MissingFields } from '../constants';

/**
 * Props for the MissingJobInfoFormContent component.
 */
interface MissingJobInfoFormContentProps {
  missingFields?: MissingFields[];
  register: UseFormRegister<Record<string, string>>;
  errors: FieldErrors;
}

/**
 * MissingJobInfoFormContent Component
 *
 * This component renders the form content for missing job information.
 *
 * @component
 *
 * @param {MissingJobInfoFormContentProps} props - The properties for the component.
 * @param {MissingFields[]} [props.missingFields] - Array of missing fields to be filled in the form.
 * @param {UseFormRegister<any>} props.register - The register function from react-hook-form.
 * @param {FieldErrors} props.errors - The errors object from react-hook-form.
 *
 * @returns {JSX.Element} The rendered MissingJobInfoFormContent component.
 */
const MissingJobInfoFormContent = ({
  missingFields,
  errors,
  register,
}: MissingJobInfoFormContentProps) => (
  <Stack gap={3}>
    Some required information could not be found in the job description you provided. Add the
    missing information to proceed.
    <form onSubmit={(e) => e.preventDefault()}>
      <Stack sx={{ gap: 2.5 }}>
        {missingFields?.map((field, index) => (
          <Stack gap={1} key={field.name}>
            <InputLabel htmlFor={field.name} label={field.label} required />
            <TextField
              id={field.name}
              placeholder={field.placeholder}
              {...register(field.name, {
                required: 'This field is required',
                validate: (value) => value.trim() !== '' || 'Whitespace-only input is not allowed',
              })}
              sx={{ paddingBottom: 0.5 }}
              inputProps={{ maxLength: 64 }}
              error={!!errors[field.name]}
              autoFocus={index === 0}
              fullWidth
            />
          </Stack>
        ))}
      </Stack>
    </form>
  </Stack>
);

export default MissingJobInfoFormContent;
