const ModernBaseStyles = () => (
  <style>
    {`  
          hr {
            color: #A2A2A2; 
            width: 100%;
            margin: 13px 0px 16px 0px;
          }

          .resume-template.modern-template .page-content-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 10px;
            flex-grow: 1;
          } 

          .resume-template.modern-template .column1 {
            width: 70%;
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 16px 0px;
          } 

          .resume-template.modern-template .column2 {
            width: 30%;
            display: flex;
            flex-direction: column;
            gap: 3px;
            background-color: #F3F3F3;
            padding: 16px 12px;
          } 
        
          .resume-template li {
            font-size: 9pt;
            font-weight: 400;
            font-style: normal;
            line-height: 1.4;
            font-family: 'Inter', sans-serif;
          }

          .resume-template .html-content .editor-paragraph {
            padding: 0;
            margin: 0;
            font-size: 9pt;
            font-weight: 400;
            font-style: normal;
            line-height: 1.4;
            font-family: 'Inter', sans-serif;
          }

          .resume-template .html-content .editor-listitem,
          .resume-template .html-content li {
            margin: 3px 3px 3px 20px;
            /* Body1 */
            font-size: 9pt;
            font-weight: 400;
            font-style: normal;
            line-height: 1.4;
            font-family: 'Inter', sans-serif;
          }

          .resume-template .header-info .html-content .editor-paragraph {
            padding: 0;
            margin: 0;
            font-size: 12pt;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            font-family: 'Inter', sans-serif;
          }

          .resume-template .contact-info .bulleted-list {
            list-style-type: none;
          }    
          
          .resume-template .contact-info .bulleted-list li {
            margin: 0px;
          }

          .resume-template .bulleted-list li.location::before {
            float: left;
            padding-right: 6px;
            content: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 6C6.275 6 6.51042 5.90208 6.70625 5.70625C6.90208 5.51042 7 5.275 7 5C7 4.725 6.90208 4.48958 6.70625 4.29375C6.51042 4.09792 6.275 4 6 4C5.725 4 5.48958 4.09792 5.29375 4.29375C5.09792 4.48958 5 4.725 5 5C5 5.275 5.09792 5.51042 5.29375 5.70625C5.48958 5.90208 5.725 6 6 6ZM6 11C4.65833 9.85833 3.65625 8.79792 2.99375 7.81875C2.33125 6.83958 2 5.93333 2 5.1C2 3.85 2.40208 2.85417 3.20625 2.1125C4.01042 1.37083 4.94167 1 6 1C7.05833 1 7.98958 1.37083 8.79375 2.1125C9.59792 2.85417 10 3.85 10 5.1C10 5.93333 9.66875 6.83958 9.00625 7.81875C8.34375 8.79792 7.34167 9.85833 6 11Z' fill='black'/%3E%3C/svg%3E");
          }

          .resume-template .bulleted-list li.phone::before {
            float: left;
            padding-right: 6px;
            content: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.975 10.5C8.93333 10.5 7.90417 10.2729 6.8875 9.81875C5.87083 9.36458 4.94583 8.72083 4.1125 7.8875C3.27917 7.05417 2.63542 6.12917 2.18125 5.1125C1.72708 4.09583 1.5 3.06667 1.5 2.025C1.5 1.875 1.55 1.75 1.65 1.65C1.75 1.55 1.875 1.5 2.025 1.5H4.05C4.16667 1.5 4.27083 1.53958 4.3625 1.61875C4.45417 1.69792 4.50833 1.79167 4.525 1.9L4.85 3.65C4.86667 3.78333 4.8625 3.89583 4.8375 3.9875C4.8125 4.07917 4.76667 4.15833 4.7 4.225L3.4875 5.45C3.65417 5.75833 3.85208 6.05625 4.08125 6.34375C4.31042 6.63125 4.5625 6.90833 4.8375 7.175C5.09583 7.43333 5.36667 7.67292 5.65 7.89375C5.93333 8.11458 6.23333 8.31667 6.55 8.5L7.725 7.325C7.8 7.25 7.89792 7.19375 8.01875 7.15625C8.13958 7.11875 8.25833 7.10833 8.375 7.125L10.1 7.475C10.2167 7.50833 10.3125 7.56875 10.3875 7.65625C10.4625 7.74375 10.5 7.84167 10.5 7.95V9.975C10.5 10.125 10.45 10.25 10.35 10.35C10.25 10.45 10.125 10.5 9.975 10.5Z' fill='black'/%3E%3C/svg%3E");
          }

          .resume-template .bulleted-list li.email::before {
            float: left;
            padding-right: 6px;
            content: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 10C1.725 10 1.48958 9.90208 1.29375 9.70625C1.09792 9.51042 1 9.275 1 9V3C1 2.725 1.09792 2.48958 1.29375 2.29375C1.48958 2.09792 1.725 2 2 2H10C10.275 2 10.5104 2.09792 10.7063 2.29375C10.9021 2.48958 11 2.725 11 3V9C11 9.275 10.9021 9.51042 10.7063 9.70625C10.5104 9.90208 10.275 10 10 10H2ZM6 6.5L2 4V9H10V4L6 6.5ZM6 5.5L10 3H2L6 5.5ZM2 4V3V9V4Z' fill='black'/%3E%3C/svg%3E");
          }

          .resume-template .bulleted-list li.linkedin::before {
            float: left;
            padding-right: 6px;
            content: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.8 10.8V7.28399C10.8 5.55599 10.428 4.23599 8.41199 4.23599C7.43999 4.23599 6.79199 4.76399 6.52799 5.26799H6.50399V4.39199H4.59599V10.8H6.58799V7.61999C6.58799 6.77999 6.74399 5.97599 7.77599 5.97599C8.79599 5.97599 8.80799 6.92399 8.80799 7.66799V10.788H10.8V10.8Z' fill='black'/%3E%3Cpath d='M1.356 4.392H3.348V10.8H1.356V4.392Z' fill='black'/%3E%3Cpath d='M2.352 1.2C1.716 1.2 1.2 1.716 1.2 2.352C1.2 2.988 1.716 3.516 2.352 3.516C2.988 3.516 3.504 2.988 3.504 2.352C3.504 1.716 2.988 1.2 2.352 1.2Z' fill='black'/%3E%3C/svg%3E");
          }
        `}
  </style>
);

export default ModernBaseStyles;
