import { useMemo } from 'react';

import type { JobDescriptionXRay } from '../../services/jobDescriptions';
import { isNotNilOrEmpty } from '../../utils';
import Grid from '../common/GridCustom/Grid';
import GridItem from '../common/GridCustom/GridItem';
import JobContentList from './components/JobContentList';
import JobSummary from './components/JobSummary';
import SkillsRenderer from './components/SkillsRenderer';

// Enum for job xray keys
enum JdKeys {
  JOB_SUMMARY = 'summary',
  EXPERIENCE_YEARS = 'years_of_experience',
  RESPONSIBILITIES = 'responsibilities',
  SKILLS = 'skills',
}

interface JobXRayProps {
  jobXRay: JobDescriptionXRay | undefined;
}

const JobXRay = ({ jobXRay }: JobXRayProps) => {
  const jobSummary = useMemo(
    () => ({
      job_company: jobXRay?.company || '',
      job_location: {
        city: jobXRay?.city || '',
        state: jobXRay?.state || '',
        country: jobXRay?.country || '',
      },
      employment_type: jobXRay?.employment_type || '',
      work_arrangement: jobXRay?.work_arrangement || '',
      salary_range: jobXRay?.salary_range || '',
      job_title: jobXRay?.title || '',
    }),
    [jobXRay],
  );

  return (
    <>
      {isNotNilOrEmpty(jobXRay) && (
        <Grid sx={{ gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr' }}>
          <GridItem
            sx={{
              gridArea: {
                xs: '2 / 1 / span 1 / span 6',
                md: '1 / 1 / span 1 / span 4',
              },
            }}
          >
            {jobXRay?.[JdKeys.EXPERIENCE_YEARS] && (
              <JobContentList
                dataList={jobXRay[JdKeys.EXPERIENCE_YEARS]}
                title="Years of Experience"
              />
            )}
          </GridItem>
          <GridItem
            sx={{
              gridArea: {
                xs: '1 / 1 / span 1 / span 6',
                md: '1 / 5 / span 2 / span 2',
              },
            }}
          >
            {isNotNilOrEmpty(jobXRay) && isNotNilOrEmpty(jobSummary) && (
              <JobSummary summary={jobSummary} />
            )}
          </GridItem>
          <GridItem
            sx={{
              gridArea: {
                xs: '4 / 1 / span 1 / span 6',
                md: '2 / 1 / span 4 / span 4',
              },
            }}
          >
            {jobXRay?.[JdKeys.RESPONSIBILITIES] && (
              <JobContentList
                dataList={jobXRay[JdKeys.RESPONSIBILITIES]}
                title="Responsibilities"
              />
            )}
          </GridItem>
          <GridItem
            sx={{
              gridArea: {
                xs: '3 / 1 / span 1 / span 6',
                md: '3 / 5 / span 1 / span 2',
              },
            }}
          >
            {jobXRay?.[JdKeys.SKILLS] && (
              <SkillsRenderer dataList={jobXRay[JdKeys.SKILLS]} title="Skills Required" />
            )}
          </GridItem>
        </Grid>
      )}
    </>
  );
};
export default JobXRay;
