import { useCallback, useMemo, useState } from 'react';
import { useLocation, useMatch } from 'react-router-dom';

import { Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useQuery } from '@tanstack/react-query';

import JobXRay from '../../components/JobXRay';
import BreadCrumbs from '../../components/common/BreadCrumbs';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import Tabs from '../../components/common/Tabs';
import { useSnackbar } from '../../contexts/snackbar';
import useIsMobile from '../../hooks/useIsMobile';
import RoutePaths from '../../routes/RoutePaths';
import type { JobDescriptionXRay } from '../../services/jobDescriptions';
import { getJobXRay, getRawJobDescription } from '../../services/jobDescriptions';
import { useSideBarStore } from '../../stores/SideBarStore';
import RawJobDescription from './components/RawJobDescription';
import type { RawJdData } from './components/interface';

enum JobXRaysSection {
  JD_XRAY = 'jd-xray',
  RAW_JD = 'raw-jd',
}
const JobXrayView = () => {
  const [selectedSection, setSelectedSection] = useState<string | null>(JobXRaysSection.JD_XRAY);
  const { isOpen } = useSideBarStore();
  const isMobile = useIsMobile();
  const match = useMatch(RoutePaths.JOB_XRAY);
  const jobId = match?.params.jobId;

  const { showSnackbar } = useSnackbar();
  const location = useLocation();

  const getJdXray = useCallback(async () => {
    let res;
    try {
      res = await getJobXRay(jobId as string);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      showSnackbar(
        'error',
        error.response?.data?.errors || 'Failed to load data. Please try again.',
      );
    }

    return res as JobDescriptionXRay;
  }, [jobId, showSnackbar]);

  const { data: jobXRay, isLoading: isJdXrayLoading } = useQuery<JobDescriptionXRay>({
    enabled: !!jobId && selectedSection === JobXRaysSection.JD_XRAY,
    queryKey: [JobXRaysSection.JD_XRAY, jobId],
    queryFn: getJdXray,
    retry: 2,
  });

  const getJobDescription = useCallback(async () => {
    let res;
    try {
      res = await getRawJobDescription(jobId as string);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      showSnackbar(
        'error',
        error.response?.data?.errors || 'Failed to load data. Please try again.',
      );
    }

    return res;
  }, [jobId, showSnackbar]);

  const { data: rawJd, isLoading: isRawJdLoading } = useQuery<RawJdData>({
    enabled: !!jobId && selectedSection === JobXRaysSection.RAW_JD,
    queryKey: [JobXRaysSection.RAW_JD, jobId],
    queryFn: getJobDescription,
    retry: 2,
  });

  const TabsContent = [
    {
      value: JobXRaysSection.JD_XRAY,
      label: 'Job X-Ray',
      icon: 'fi fi-rr-search-alt',
    },
    {
      value: JobXRaysSection.RAW_JD,
      label: 'Job Description',
      icon: 'fi fi-rr-align-justify',
    },
  ];

  const BreadCrumbLinks = useMemo(
    () =>
      isMobile
        ? [{ label: 'View', href: location.pathname }]
        : [
            { label: 'Jobs', href: RoutePaths.JOBS },
            { label: 'View', href: location.pathname },
          ],
    [isMobile, location.pathname],
  );

  const isDataLoading = () => {
    switch (selectedSection) {
      case JobXRaysSection.JD_XRAY:
        return isJdXrayLoading;
      case JobXRaysSection.RAW_JD:
        return isRawJdLoading;
      default:
        return isJdXrayLoading;
    }
  };

  return (
    <Stack
      sx={{
        backgroundColor: 'common.white',
        // TODO: quick fix for padding, we need to handle page layout margins and padding
        // eslint-disable-next-line no-nested-ternary
        paddingLeft: isMobile ? 1.5 : isOpen ? 4 : 7,
        paddingRight: isMobile ? 1.5 : 4,
        paddingY: isMobile ? 2.5 : 4,
        overflow: 'auto',
        boxSizing: 'border-box',
      }}
    >
      {isDataLoading() && <LoadingSpinner />}
      {/* Job Xray page */}
      <Grid container sx={{ justifyContent: 'flex-start' }}>
        <Grid xs={12} sx={{ marginBottom: { xs: 1.5, sm: 4 } }}>
          <Stack gap={0.5}>
            <BreadCrumbs links={BreadCrumbLinks} />
            {/* Hide for mobile devices */}
            {!isMobile && (
              <Stack
                sx={{ paddingLeft: 3.75, flexDirection: 'row', alignItems: 'flex-start', gap: 0.5 }}
              >
                <Typography variant="body2">{jobXRay?.company}</Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {jobXRay?.title}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Grid>
        {/* sections */}
        <Grid xs={12} sx={{ paddingX: { xs: 0, sm: 3.5 } }}>
          <Tabs tabs={TabsContent} onChange={setSelectedSection}>
            <JobXRay jobXRay={jobXRay} />
            <RawJobDescription rawJd={rawJd} />
          </Tabs>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default JobXrayView;
