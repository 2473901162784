import { useEffect } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import { Stack, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';

import { monthList, yearsListFrom1971 } from '../../../constants';
import type { PublicationInfo } from '../../../interface';
import {
  getIntegerMonth,
  getStringMonth,
  isValidMonth,
  validateIssuedYear,
} from '../../../utils/dateUtils';
import FormActions from '../../Form/FormActions';
import Dropdown from '../../common/Dropdown';
import InputLabel from '../../common/InputLabel';

interface PublicationEditorProps {
  onUpdate: (publication: PublicationInfo, method: string) => void;
  defaultPublication?: PublicationInfo;
}

const PublicationEditor = ({ onUpdate, defaultPublication }: PublicationEditorProps) => {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();
  const method = defaultPublication?._id === '' ? 'add' : 'edit';

  // TODO: Rework on setting default values for the inputs
  useEffect(() => {
    setValue('title', defaultPublication?.title || '');
    setValue('publisher', defaultPublication?.publisher || '');
    setValue('issued_month', getStringMonth(defaultPublication?.issued_month));
    setValue('issued_year', defaultPublication?.issued_year);
  }, [setValue, defaultPublication]);

  const onSubmit: SubmitHandler<{
    title: string;
    publisher: string;
    issued_month: string | null;
    issued_year: number | null;
  }> = (publicationData) => {
    onUpdate(
      {
        _id: defaultPublication?._id || '',
        ...publicationData,
        issued_month: isValidMonth(publicationData.issued_month)
          ? getIntegerMonth(publicationData.issued_month)
          : null,
      },
      method,
    );
  };

  const onValidatePublishYear = (issuedYear: number) => {
    if (!validateIssuedYear(getValues('issued_month'), issuedYear)) {
      return 'Publish year is required';
    }
    return true;
  };

  const onSaveClick = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleSubmit(onSubmit as any)();
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Stack gap={3}>
        <Stack gap={1}>
          <InputLabel htmlFor="title" label="Publication Title" required />
          {/* TODO: Use common TextField component */}
          <TextField
            id="title"
            placeholder="My life as a comedian AI"
            {...register('title', {
              required: 'Enter a publication title',
            })}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid',
                  borderColor: 'border.light',
                  borderRadius: '8px',
                  padding: '10px',
                },
              },
            }}
            inputProps={{
              maxLength: 1024,
            }}
            defaultValue={defaultPublication?.title}
            variant="outlined"
            error={!!errors.title}
            fullWidth
          />
          {!!errors?.title && (
            <Typography variant="assistive" color="error">
              {errors?.title?.message?.toString()}
            </Typography>
          )}
        </Stack>
        <Stack gap={1}>
          <InputLabel htmlFor="publisher" label="Publisher" />
          <TextField
            id="publisher"
            placeholder="New York Times"
            {...register('publisher')}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid',
                  borderColor: 'border.light',
                  borderRadius: '8px',
                  padding: '10px',
                },
              },
            }}
            inputProps={{
              maxLength: 1024,
            }}
            defaultValue={defaultPublication?.publisher}
            variant="outlined"
            fullWidth
          />
        </Stack>
        <Stack gap={1}>
          <InputLabel label="Publish Date" />
          <Grid2 justifyContent="space-between" spacing="8px" container>
            <Grid2 xs={6}>
              <Dropdown
                {...register('issued_month')}
                items={monthList}
                placeholder="Select Publish Month"
                defaultValue={getStringMonth(defaultPublication?.issued_month)}
                control={control}
              />
            </Grid2>
            <Grid2 xs={6}>
              <Dropdown
                {...register('issued_year', {
                  valueAsNumber: true,
                  validate: onValidatePublishYear,
                })}
                items={yearsListFrom1971}
                placeholder="Select Publish Year"
                defaultValue={defaultPublication?.issued_year}
                control={control}
              />
            </Grid2>
          </Grid2>
        </Stack>
        <FormActions onSubmit={onSaveClick} />
      </Stack>
    </form>
  );
};

export default PublicationEditor;
