import { Chip, CircularProgress, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ResponseStatus } from '../../interface';
import Icon from './Icon';

export interface ProgressStatusChipProps {
  progress?: number;
  status?: ResponseStatus;
  onSuccess?: JSX.Element;
  onPending?: JSX.Element;
}

export const StyledChip = styled(Chip)({
  borderRadius: '32px',
  backgroundColor: 'background.mediumLight',
  padding: '4px 8px',
  maxWidth: 'fit-content',
  maxHeight: '26px',
  '& .MuiChip-label': {
    paddingLeft: '12px',
    paddingRight: '6px',
    color: 'text.primary',

    /* Assistive */
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px', // 150%
    letterSpacing: '-0.06px',
  },
});

const ProgressStatusChip = ({
  progress,
  status,
  onPending,
  onSuccess,
}: ProgressStatusChipProps) => {
  const theme = useTheme();

  switch (status) {
    case ResponseStatus.TODO:
    case ResponseStatus.IN_PROGRESS:
      return (
        <StyledChip
          icon={<CircularProgress size={14} sx={{ color: 'background.mediumDark' }} />}
          label={`${progress}% Done`}
        />
      );

    case ResponseStatus.ERROR:
      return (
        <StyledChip
          icon={
            <Icon
              fontSize="12px"
              className="fi fi-rr-exclamation"
              color={theme.palette.error.main}
            />
          }
          label="Failed"
          sx={{
            backgroundColor: 'error.light',
          }}
        />
      );

    case ResponseStatus.PENDING:
    case ResponseStatus.MISSING:
    case ResponseStatus.SELECT_KEYWORDS:
      return (
        onPending || (
          <StyledChip
            icon={
              <Icon
                fontSize="14px"
                className="fi fi-rr-shield-exclamation"
                color={theme.palette.error.main}
              />
            }
            label="Add Missing Info"
            sx={{
              backgroundColor: 'error.light',
            }}
          />
        )
      );

    case ResponseStatus.DONE:
      return onSuccess || null;

    default:
      return null;
  }
};

export default ProgressStatusChip;
