import { forwardRef } from 'react';
import type { FieldErrors } from 'react-hook-form';

import type { TextFieldProps } from '@mui/material';
import { Stack, Typography } from '@mui/material';

import InputLabel from './InputLabel';
import TextField from './TextField';

type TextFieldWithLabelProps = {
  id: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  errors?: FieldErrors;
  showOptionalLabel?: boolean;
} & TextFieldProps;

/**
 * A custom text field component with a label.
 *
 * @component
 * @example
 * // Usage:
 * <TextFieldWithLabel
 *   id="myTextField"
 *   label="Name"
 *   placeholder="Enter your name"
 *   required={true}
 *   errors={errors}
 *   // ...rest of the props
 * />
 *
 * @param {string} id - The unique identifier for the text field.
 * @param {string} label - The label text for the text field.
 * @param {string} placeholder - The placeholder text for the text field.
 * @param {boolean} [required=false] - Indicates if the text field is required.
 * @param {boolean} [showOptionalLabel=false] - Indicates if the text field is optional.
 * @param {object} [errors={}] - An object containing error messages for the text field.
 * @param {React.Ref<HTMLInputElement>} ref - The ref to be forwarded to the underlying input element.
 * @returns {JSX.Element} The rendered TextFieldWithLabel component.
 */
const TextFieldWithLabel = forwardRef<HTMLInputElement, TextFieldWithLabelProps>(
  (
    { id, label, placeholder, required = false, showOptionalLabel, errors = {}, ...restProps },
    ref,
  ) => (
    <Stack gap={1}>
      <InputLabel
        htmlFor={id}
        required={required}
        label={
          <>
            {label}
            {showOptionalLabel && (
              <Typography variant="assistive" color="text.secondary">
                {' Optional'}
              </Typography>
            )}
          </>
        }
      />
      <TextField
        id={id}
        placeholder={placeholder}
        error={!!errors[id]}
        helperText={errors[id]?.message?.toString()}
        fullWidth
        {...restProps}
        ref={ref}
      />
    </Stack>
  ),
);

export default TextFieldWithLabel;
