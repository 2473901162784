import {
  $getNodeByKey,
  $getSelection,
  $isRangeSelection,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  FORMAT_TEXT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from 'lexical';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { $isCodeNode, getCodeLanguages, getDefaultCodeLanguage } from '@lexical/code';
import {
  $isListNode,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isHeadingNode } from '@lexical/rich-text';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import { Divider as MuiDivider } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';

import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import theme from '../../../theme/theme';
import { isNotNilOrEmpty } from '../../../utils';
import BootstrapTooltip from '../../common/BootstrapTooltip';
import Icon from '../../common/Icon';
import ToolbarButton from './ToolbarButton';

const LowPriority = 1;

/**
 * Divider component for separating toolbar items.
 */
const Divider = () => (
  <MuiDivider
    orientation="vertical"
    sx={{ width: 2, height: 16, marginY: 'auto', color: 'border.light' }}
  />
);

interface SelectProps {
  onChange: (e: {
    target: {
      value: string;
    };
  }) => void;
  className: string;
  options: string[];
  value: string;
}

/**
 * Select component for choosing code language in the toolbar.
 *
 * @param {SelectProps} props - The properties for the Select component.
 * @returns {JSX.Element} The rendered Select component.
 */
const Select = ({ onChange, className, options, value }: SelectProps) => (
  <select className={className} onChange={onChange} value={value}>
    {/* TODO: fix option node */}
    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
    <option hidden value="" />
    {options.map((option) => (
      <option key={option} value={option}>
        {option}
      </option>
    ))}
  </select>
);

/**
 * Interface representing an external control for the toolbar in the RichTextEditor.
 */
export interface ToolbarExternalControl {
  /**
   * The title of the toolbar control.
   */
  title: string;

  /**
   * The icon associated with the toolbar control.
   */
  icon: string;

  /**
   * Specifies whether the control is disabled.
   */
  disabled: boolean;

  /**
   * The callback function to be executed when the control is clicked.
   */
  onClick: () => void;

  /**
   * (Optional) The callback function to be executed when a disabled control item is clicked.
   */
  onDisabledItemClick?: () => void;

  /**
   * (Optional) The tooltip text to be displayed for the control. Can be a string or a React node.
   */
  tooltipText?: string | React.ReactNode;
}

export type ToolbarExternalControls = ToolbarExternalControl[];

/**
 * ToolbarPlugin is a React component that provides a rich text editor toolbar with various formatting options.
 * It integrates with the Lexical editor to offer functionalities such as undo, redo, text formatting, and list insertion.
 *
 * @param {Object} props - The properties object.
 * @param {ToolbarExternalControls[]} [props.externalControls=[]] - An array of external controls to be added to the toolbar.
 * @param {boolean} [props.disabled] - A flag to disable the toolbar controls.
 *
 * @returns {JSX.Element} The rendered toolbar component.
 *
 * @component
 * @example
 * return (
 *   <ToolbarPlugin
 *     externalControls={[
 *       { title: 'Custom Button', icon: 'custom-icon', onClick: handleClick, tooltipText: 'Custom Tooltip' }
 *     ]}
 *     disabled={false}
 *   />
 * );
 */
export const ToolbarPlugin = ({
  externalControls = [],
  disabled,
}: {
  externalControls?: ToolbarExternalControls;
  disabled?: boolean;
}) => {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [blockType, setBlockType] = useState('paragraph');
  const [selectedElementKey, setSelectedElementKey] = useState<string | null>(null);
  const [codeLanguage, setCodeLanguage] = useState('');
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);

  const { isMobileOrTablet } = useResponsiveDevice();

  /**
   * Updates the toolbar state based on the current selection in the editor.
   */
  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          setBlockType(type);
          if ($isCodeNode(element)) {
            setCodeLanguage(element.getLanguage() || getDefaultCodeLanguage());
          }
        }
      }
      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
    }
  }, [editor]);

  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateToolbar();
          });
        }),
        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          () => {
            updateToolbar();
            return false;
          },
          LowPriority,
        ),
        editor.registerCommand(
          CAN_UNDO_COMMAND,
          (payload) => {
            setCanUndo(payload);
            return false;
          },
          LowPriority,
        ),
        editor.registerCommand(
          CAN_REDO_COMMAND,
          (payload) => {
            setCanRedo(payload);
            return false;
          },
          LowPriority,
        ),
      ),
    [editor, updateToolbar],
  );

  const codeLanguges = useMemo(() => getCodeLanguages(), []);

  /**
   * Handles the selection of a code language from the dropdown.
   *
   * @param {Object} e - The event object.
   * @param {string} e.target.value - The selected code language.
   */
  const onCodeLanguageSelect = useCallback(
    (e: { target: { value: string } }) => {
      editor.update(() => {
        if (selectedElementKey !== null) {
          const node = $getNodeByKey(selectedElementKey);
          if ($isCodeNode(node)) {
            node.setLanguage(e.target.value);
          }
        }
      });
    },
    [editor, selectedElementKey],
  );

  /**
   * Toggles the bullet list format in the editor.
   */
  const formatBulletList = () => {
    if (blockType !== 'ul') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
      setBlockType('ul');
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
      setBlockType('paragraph');
    }
  };

  /**
   * Handles the click event for external controls.
   *
   * @param {ToolbarExternalControl} control - The external control object.
   * @returns {() => void} A function that handles the click event.
   */
  const onExternalControlClick = (control: ToolbarExternalControl) => () => {
    if (control.disabled || disabled) {
      if (control.onDisabledItemClick && isMobileOrTablet) {
        control.onDisabledItemClick();
      }
    } else {
      control.onClick();
    }
  };

  /**
   * Renders an external control button.
   *
   * @param {ToolbarExternalControl} control - The external control object.
   * @returns {JSX.Element} The rendered external control button.
   */
  const getExternalControlButton = (control: ToolbarExternalControl) => {
    const externalControlButton = (
      <ToolbarButton
        key={control.title}
        onClick={() => onExternalControlClick(control)()}
        aria-label={control.title}
        icon={control.icon}
        iconStyle={{
          color:
            control?.disabled || disabled
              ? theme.palette.text.tertiary
              : theme.palette.text.secondary,
        }}
        sx={{ color: control?.disabled || disabled ? 'text.tertiary' : 'text.primary' }}
        disabled={(control.disabled || disabled) && !isMobileOrTablet}
      >
        {control.title}
      </ToolbarButton>
    );

    if (isMobileOrTablet) {
      return externalControlButton;
    }
    return (
      <BootstrapTooltip title={control.tooltipText} placement="top">
        <span style={{ cursor: control?.disabled || disabled ? 'not-allowed' : 'pointer' }}>
          {externalControlButton}
        </span>
      </BootstrapTooltip>
    );
  };

  return (
    <div className="toolbar" ref={toolbarRef}>
      <Grid2
        container
        spacing={0.5}
        sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Grid2 sx={{ display: 'flex', gap: 0.5 }}>
          {/* main controls */}
          <ToolbarButton
            disabled={!canUndo}
            onClick={() => {
              editor.dispatchCommand(UNDO_COMMAND, undefined);
            }}
            icon="fi fi-rr-undo-alt"
            aria-label="Undo"
          />
          <ToolbarButton
            disabled={!canRedo}
            onClick={() => {
              editor.dispatchCommand(REDO_COMMAND, undefined);
            }}
            icon="fi fi-rr-redo-alt"
            aria-label="Redo"
          />
          <Divider />

          {blockType === 'code' ? (
            <>
              <Select
                className="toolbar-item code-language"
                onChange={onCodeLanguageSelect}
                options={codeLanguges}
                value={codeLanguage}
              />
              <Icon className="chevron-down inside fi fi-rr-angle-small-down" />
            </>
          ) : (
            <>
              <ToolbarButton
                onClick={() => {
                  editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
                }}
                aria-label="Format Bold"
                icon="fi fi-rr-bold"
                isActive={isBold}
              />
              <ToolbarButton
                onClick={() => {
                  editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
                }}
                aria-label="Format Italics"
                icon="fi fi-rr-italic"
                isActive={isItalic}
              />
              <ToolbarButton
                onClick={() => {
                  editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
                }}
                aria-label="Format Underline"
                icon="fi fi-rr-underline"
                isActive={isUnderline}
              />
              <Divider />
              {/* button for unordered list */}
              <ToolbarButton
                onClick={formatBulletList}
                aria-label="Format Unordered List"
                icon="format fi fi-rr-list"
                isActive={blockType === 'ul'}
              />
            </>
          )}
        </Grid2>
        <Grid2 sx={{ display: 'flex', gap: 0.5 }}>
          {/* external controls */}
          {isNotNilOrEmpty(externalControls) &&
            externalControls.map((control) => getExternalControlButton(control))}
        </Grid2>
      </Grid2>
    </div>
  );
};
