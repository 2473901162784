import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import useResponsiveDevice from '../../hooks/useResponsiveDevice';
import { EventAction, EventCategory, logEvent } from '../../services/analytics';
import type { SubscriptionPlan } from '../../services/billing';
import {
  BillingKeys,
  PlanTypes,
  cancelSubscription,
  createSubscriptionSession,
  getCurrentSubscription,
  getSubcriptionPlans,
  reSubscribe,
} from '../../services/billing';
import { useAccountStatusStore } from '../../stores/AccountStatusStore';
import { useAuthStore } from '../../stores/AuthStore';
import { useSnackbar } from '../snackbar';
import DesktopManageSubcriptionModal from './DesktopManageSubcriptionModal';
import MobileManageSubscriptionContent from './MobileManageSubscriptionContent';

interface ManageSubscriptionModalContextProps {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}

/**
 * Context for managing the subscription modal state.
 *
 * This context provides the state and dispatch functions for managing the subscription modal.
 * It is initialized with an undefined value and should be provided with a valid
 * `ManageSubscriptionModalContextProps` object when used.
 *
 * @type {React.Context<ManageSubscriptionModalContextProps | undefined>}
 */
const ManageSubscriptionModalContext = createContext<
  ManageSubscriptionModalContextProps | undefined
>(undefined);

/**
 * Provides context and functionality for managing subscription modals.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {React.ReactElement} props.children - The child components to be wrapped by the provider.
 *
 * @returns {JSX.Element} The provider component that wraps its children with subscription modal management context.
 *
 * @example
 * <ManageSubscriptionModalProvider>
 *   <YourComponent />
 * </ManageSubscriptionModalProvider>
 *
 * @description
 * This provider component manages the state and functions related to subscription modals.
 * It handles opening and closing of the modal, as well as subscription updates, downgrades, and resubscriptions.
 * It uses various hooks and mutations to interact with the subscription and account status APIs.
 *
 * @remarks
 * The component uses the `useQuery` and `useMutation` hooks from `react-query` to fetch and mutate subscription data.
 * It also uses the `useSnackbar` hook to display notifications to the user.
 * The modal content is conditionally rendered based on the device type (mobile or desktop).
 */
export const ManageSubscriptionModalProvider = ({ children }: { children: React.ReactElement }) => {
  const [isOpen, setIsOpen] = useState(false);
  const timer = useRef<NodeJS.Timeout | null>(null);
  const [retryCount, setRetryCount] = useState(0);

  const [previousSubscription, setPreviousSubscription] = useState<SubscriptionPlan>();

  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { initialize: initializeAccountStatusStore, accountStatus } = useAccountStatusStore();
  const { isLoggedIn } = useAuthStore();
  const { isMobileOrTablet } = useResponsiveDevice();

  // cleanup timer on unmount
  useEffect(
    () => () => {
      if (timer.current) clearTimeout(timer.current);
    },
    [],
  );

  // TODO: refactor, remove this dependency on SubscriptionPlan, use AccountStatus instead
  // get current subscription from billing service
  const { data: subscription } = useQuery<SubscriptionPlan>({
    queryKey: [BillingKeys.CURRENT_SUBSCRIPTION_PLAN],
    queryFn: getCurrentSubscription,
    enabled: isLoggedIn && !!accountStatus?.subscription,
  });

  // get stripe subscription plans from billing service
  const { data: subscriptionPlans } = useQuery({
    queryKey: [BillingKeys.SUBSCRIPTION_PLANS],
    queryFn: getSubcriptionPlans,
    enabled: isLoggedIn && !!subscription,
  });

  // TODO: create a custom hook for mutationWithRetry
  // A mutation to reinitialize account status store
  // checks if account status is updated and shows success message
  // else keeps retrying until it is updated for 1 minute
  const { mutate: fetchAccountStatusForPlanChange } = useMutation({
    mutationFn: initializeAccountStatusStore,
    onSuccess(data) {
      // TODO: add a loading state to show a spinner while updating the account status
      if (
        data?.subscription !== previousSubscription?.planName ||
        data?.isPlanCancelled !== previousSubscription?.isPlanCancelled
      ) {
        setPreviousSubscription(undefined);
        setTimeout(() => {
          showSnackbar(
            'success',
            `Your subscription has been successfully ${
              PlanTypes.PRO ? 'resubscribed' : 'canceled'
            }.`,
          );
          queryClient.invalidateQueries([BillingKeys.CURRENT_SUBSCRIPTION_PLAN]);
          queryClient.invalidateQueries([BillingKeys.INVOICES]);
          logEvent(
            EventCategory.FORM_SUBMISSION,
            EventAction.SUBMIT,
            `User subscription ${PlanTypes.PRO ? 'resubscribed' : 'canceled'}`,
          );
          setRetryCount(0);
        }, 1000);
        return;
      }

      // keep retrying until account status is updated for <= 60 retries
      if (retryCount < 60) {
        setRetryCount(retryCount + 1);
        timer.current = setTimeout(() => {
          fetchAccountStatusForPlanChange();
        }, 1000);
      } else {
        setRetryCount(0);
        showSnackbar('error', 'Problem updating the application status. Please refresh the page.');
      }
    },
  });

  // create a subcription session mutation and redirect to the checkout page
  const { mutate: createSubscriptionSessionMutation } = useMutation({
    mutationFn: createSubscriptionSession,
    onSuccess: (data) => {
      window.location.href = data.url;
    },
    onError: () => {
      //   toggleLoading(false);
      showSnackbar(
        'info',
        'We encountered an issue processing your data. Please try resubmitting or contact support',
      );
    },
  });

  // create a cancel subscription mutation and show the snackbar message
  const { mutate: cancelSubscriptionMutation } = useMutation({
    mutationFn: cancelSubscription,
    onSuccess: () => {
      setPreviousSubscription(subscription);
      fetchAccountStatusForPlanChange();
    },
    onError: () => {
      //   toggleLoading(false);
      showSnackbar(
        'error',
        'Failed to cancel your subscription. Please try again or contact support',
      );
    },
  });

  const { mutate: resubscribeMutation } = useMutation({
    mutationFn: reSubscribe,
    onSuccess: () => {
      setPreviousSubscription(subscription);
      fetchAccountStatusForPlanChange();
    },
    onError: () => {
      //   toggleLoading(false);
      showSnackbar('error', 'Failed to resubscribe. Please try again or contact support');
    },
  });

  /**
   * Handles the confirmation of updating the subscription.
   * @param price_id - The price ID of the new subscription plan.
   */
  const onUpdateConfirm = useCallback(
    (price_id: string) => {
      if (!price_id) return;

      setIsOpen(false);
      // toggleLoading(true);
      // call the mutation to create a subscription session
      createSubscriptionSessionMutation(price_id);
    },
    [createSubscriptionSessionMutation],
  );

  /**
   * Handles the confirmation of downgrading the subscription.
   * @param reason - The reason for downgrading the subscription.
   */
  const onDowngradeConfirm = useCallback(
    (reason: string) => {
      if (!reason || !subscription) return;
      // toggleLoading(true);
      setIsOpen(false);

      // call the mutation to cancel the subscription
      cancelSubscriptionMutation({
        subId: subscription.id,
        reason,
      });
    },
    [subscription, cancelSubscriptionMutation],
  );

  /**
   * Handles the confirmation of resubscribing.
   * @param subscriptionId - The ID of the subscription to resubscribe.
   */
  const onResubscribeConfirm = useCallback(
    (subscriptionId: string) => {
      if (!subscriptionId) return;

      setIsOpen(false);
      // toggleLoading(true);
      // call the mutation to resubscribe
      resubscribeMutation(subscriptionId);
    },
    [resubscribeMutation],
  );

  /**
   * Opens the subscription modal if the subscription exists and the plan name is STANDARD.
   *
   * @function
   * @name openModal
   * @returns {void}
   */
  const openModal = () => setIsOpen(true);

  /**
   * Closes the subscription management modal by setting the `isOpen` state to `false`.
   */
  const closeModal = () => setIsOpen(false);

  /**
   * Memoized context value containing the state and functions to manage the subscription modal.
   *
   * @constant
   * @type {{ isOpen: boolean, openModal: () => void, closeModal: () => void }}
   * @param {boolean} isOpen - Indicates whether the modal is open.
   * @param {() => void} openModal - Function to open the modal.
   * @param {() => void} closeModal - Function to close the modal.
   */
  const ContextValue = useMemo(() => ({ isOpen, openModal, closeModal }), [isOpen]);

  const ModalsContent = useMemo(
    () =>
      isMobileOrTablet ? (
        <MobileManageSubscriptionContent
          isOpen={isOpen}
          openModal={openModal}
          closeModal={closeModal}
          subscription={subscription}
          subscriptionPlans={subscriptionPlans}
          onUpdateConfirm={onUpdateConfirm}
          onDowngradeConfirm={onDowngradeConfirm}
          onResubscribeConfirm={onResubscribeConfirm}
        />
      ) : (
        <DesktopManageSubcriptionModal
          isOpen={isOpen}
          subscription={subscription}
          subscriptionPlans={subscriptionPlans}
          onClose={closeModal}
          onUpdateConfirm={onUpdateConfirm}
          onDowngradeConfirm={onDowngradeConfirm}
          onResubscribeConfirm={onResubscribeConfirm}
        />
      ),
    [
      isMobileOrTablet,
      isOpen,
      subscription,
      subscriptionPlans,
      onUpdateConfirm,
      onDowngradeConfirm,
      onResubscribeConfirm,
    ],
  );

  return (
    <ManageSubscriptionModalContext.Provider value={ContextValue}>
      {children}
      {ModalsContent}
    </ManageSubscriptionModalContext.Provider>
  );
};

/**
 * Custom hook to access the ManageSubscriptionModal context.
 *
 * This hook provides access to the ManageSubscriptionModal context, which should be used
 * within a ManageSubscriptionModalProvider. If the hook is used outside of the provider,
 * it will throw an error.
 *
 * @returns {ManageSubscriptionModalContextType} The context value for managing subscription modal.
 * @throws {Error} If the hook is used outside of a ManageSubscriptionModalProvider.
 */
export const useManageSubscriptionModal = () => {
  const context = useContext(ManageSubscriptionModalContext);
  if (context === undefined) {
    throw new Error(
      'useManageSubscriptionModal must be used within a ManageSubscriptionModalProvider',
    );
  }
  return context;
};
