import type { LexicalEditor } from 'lexical';
import {
  $createLineBreakNode,
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $insertNodes,
} from 'lexical';
import { useCallback, useMemo, useRef, useState } from 'react';
import uuid from 'react-uuid';

import { FormHelperText, InputLabel, Stack, Typography } from '@mui/material';

import { useManageSubscriptionModal } from '../../../contexts/ManageSubscriptionModal/ManageSubscriptionModal';
import { useSnackbar } from '../../../contexts/snackbar';
import { useAbortSignal } from '../../../hooks/useAbortSignal';
import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import type { ExperienceInfo, SummaryInfo } from '../../../interface';
import { EventAction, EventCategory, logEvent } from '../../../services/analytics';
import { PlanTypes } from '../../../services/billing';
import {
  autofixProfessionalSummary,
  generateProfessionalSummary,
} from '../../../services/superEditor';
import { useAccountStatusStore } from '../../../stores/AccountStatusStore';
import type { Draft } from '../../../stores/SuperEditorDrafts';
import useDraftsStore, { DraftType } from '../../../stores/SuperEditorDrafts';
import { getDraftTooltipText, getDraftsIcon, getTextFromHtml } from '../../../utils';
import FormActions from '../../Form/FormActions';
import RichTextEditor from '../../RichTextEditor/RichTextEditor';
import type { ToolbarExternalControl } from '../../RichTextEditor/plugins/ToolbarPlugin';
import DefaultDisabledTooltipContent from '../../common/DefaultDisabledTooltipContent';
import DraftsManager from './Drafts/DraftsManager';

interface SummaryEditorProps {
  onEdit: (summary: SummaryInfo) => void;
  defaultSummary?: SummaryInfo;
  latestWorkExperiences?: ExperienceInfo;
}

const SummaryEditor = ({ onEdit, defaultSummary, latestWorkExperiences }: SummaryEditorProps) => {
  const [professionalSummary, setProfessionalSummary] = useState(
    defaultSummary?.professional_summary || '',
  );

  const richTextEditorRef = useRef<LexicalEditor>();
  const { drafts, maxDrafts, isLoading, addDraft, updateDraft, getDraft, removeDraft } =
    useDraftsStore();
  const { showSnackbar } = useSnackbar();
  const { isMobileOrTablet } = useResponsiveDevice();
  const { openModal: openManageSubscriptionModal } = useManageSubscriptionModal();

  const { abortSignal } = useAbortSignal();
  const { isGenerateEnabled, isAutofixEnabled, accountStatus } = useAccountStatusStore();

  const onRichTextEditorRef = (ref: LexicalEditor) => {
    richTextEditorRef.current = ref;
  };

  const editor = richTextEditorRef.current;

  const onReplaceDraft = (draft: Draft, forceInsertEmptyNode = false, skipEmptyCheck = false) => {
    // TODO: think about moving these to the rich text editor, might be a better idea to explore editor Commands
    if (editor) {
      editor.update(() => {
        const root = $getRoot();

        // Quickfix: editor update is running twice on first load causing append, replace to happe twice
        if (!professionalSummary && !skipEmptyCheck) {
          const tempParagraph = $createParagraphNode();
          const tempElement = $createTextNode('.');
          tempParagraph.append(tempElement);
          $insertNodes([tempParagraph]);
          setTimeout(() => {
            onReplaceDraft(draft, false, true);
          }, 0);
          return;
        }

        const paragraph = $createParagraphNode();
        const element = $createTextNode(draft.text);
        paragraph.append(element);
        root.clear();
        $insertNodes([paragraph]);

        if (forceInsertEmptyNode) {
          const tempParagraph = $createParagraphNode();
          // const lineBreak = $createLineBreakNode();
          $insertNodes([tempParagraph]);
        }

        root.selectEnd();
      });
    }
  };

  const onAppendDraft = (draft: Draft) => {
    // TODO: think about moving these to the rich text editor, might be a better idea to explore editor Commands
    if (editor) {
      editor.update(
        () => {
          const root = $getRoot();

          // Quickfix: editor update is running twice on first load causing append, replace to happe twice
          if (!professionalSummary) {
            const tempParagraph = $createParagraphNode();
            const tempElement = $createTextNode('.');
            tempParagraph.append(tempElement);
            $insertNodes([tempParagraph]);
            setTimeout(() => {
              onReplaceDraft(draft, true);
            }, 0);
            return;
          }

          const paragraph = $createParagraphNode();
          const element = $createTextNode(draft.text);
          paragraph.append(element);
          const lineBreak = $createLineBreakNode();
          $insertNodes([lineBreak, paragraph]);
          root.selectEnd();
        },
        {
          skipTransforms: true,
        },
      );
    }
  };

  const onEditorChange = (html: string) => {
    setProfessionalSummary(html);
  };

  // Define a function to handle the form submission
  const onSubmit = () => {
    // Call the callback function to update the summary
    onEdit({
      _id: defaultSummary?._id || '',
      professional_summary: professionalSummary,
    });
  };

  const onGenerateClick = useCallback(() => {
    const id = uuid();

    if (!latestWorkExperiences) {
      return;
    }

    // add empty draft
    addDraft({
      id,
      text: '',
      type: DraftType.GENERATE,
      status: 'loading',
    });

    generateProfessionalSummary({
      professionalExperience: {
        ...latestWorkExperiences,
        description: getTextFromHtml(latestWorkExperiences.description),
      },
      onProgress: (data) => {
        // add slight delay
        setTimeout(() => {
          addDraft({
            id,
            text: data,
            type: DraftType.GENERATE,
            status: 'loading',
          });
        }, 500);
      },
      onEnd: (data) => {
        // adding an artificial delay, because for small texts its too fast
        setTimeout(() => {
          logEvent(
            EventCategory.USER_INTERACTION,
            EventAction.CLICK,
            'Professional Summary Generated',
          );
          updateDraft(id, {
            id,
            text: data,
            type: DraftType.GENERATE,
            status: 'success',
          });
        }, 1000);
      },
      signal: abortSignal,
    }).catch((e: Error) => {
      if (e.name !== 'AbortError') {
        showSnackbar(
          'error',
          e?.message ||
            'An error occurred while generating Progessional Summary. Please try again later.',
        );
        const draft = getDraft(id);
        if (draft?.text === '') {
          removeDraft(id);
        } else {
          updateDraft(id, {
            type: DraftType.GENERATE,
            status: 'error',
          });
        }
      }
    });
  }, [
    latestWorkExperiences,
    addDraft,
    abortSignal,
    updateDraft,
    showSnackbar,
    getDraft,
    removeDraft,
  ]);

  const onAutofixClick = useCallback(() => {
    const id = uuid();

    if (!latestWorkExperiences) {
      return;
    }

    // add empty draft
    addDraft({
      id,
      text: '',
      type: DraftType.AUTOFIX,
      status: 'loading',
    });

    autofixProfessionalSummary({
      professionalSummary: getTextFromHtml(professionalSummary),
      onProgress: (data) => {
        // add slight delay
        setTimeout(() => {
          addDraft({
            id,
            text: data,
            type: DraftType.AUTOFIX,
            status: 'loading',
          });
        }, 500);
      },
      onEnd: (data) => {
        // adding an artificial delay, because for small texts its too fast
        setTimeout(() => {
          logEvent(
            EventCategory.USER_INTERACTION,
            EventAction.CLICK,
            'Professional Summary Autofixed',
          );
          updateDraft(id, {
            id,
            text: data,
            type: DraftType.AUTOFIX,
            status: 'success',
          });
        }, 1000);
      },
      signal: abortSignal,
    }).catch((e: Error) => {
      if (e.name !== 'AbortError') {
        showSnackbar(
          'error',
          e?.message || 'An error occurred while autofixing the Professional Summary.',
        );
        const draft = getDraft(id);
        if (draft?.text === '') {
          removeDraft(id);
        } else {
          updateDraft(id, {
            type: DraftType.AUTOFIX,
            status: 'error',
          });
        }
      }
    });
  }, [
    latestWorkExperiences,
    addDraft,
    professionalSummary,
    abortSignal,
    updateDraft,
    showSnackbar,
    getDraft,
    removeDraft,
  ]);

  const toolbarExternalControls = useMemo<ToolbarExternalControl[]>(() => {
    // Enable autofix button if description has more than 3 words
    // Enable generate button if latest work experience description has more than 10 words
    const meetsWorkExpLimit =
      getTextFromHtml(latestWorkExperiences?.description).trim().split(' ').length >= 10;

    const shouldDisableGenerate =
      isLoading || !isGenerateEnabled || drafts.length === maxDrafts || !meetsWorkExpLimit;
    const shouldDisableAutofix =
      isLoading ||
      !isAutofixEnabled ||
      drafts.length === maxDrafts ||
      getTextFromHtml(professionalSummary).trim().split(' ').length < 3;

    return [
      {
        title: 'Generate',
        icon: getDraftsIcon(DraftType.GENERATE, !isGenerateEnabled),
        disabled: shouldDisableGenerate,
        tooltipText: getDraftTooltipText(
          DraftType.GENERATE,
          'Professional Summary',
          shouldDisableGenerate,
          drafts.length === maxDrafts,
          maxDrafts,
          !isGenerateEnabled ? <DefaultDisabledTooltipContent /> : undefined,
        ),
        onClick: onGenerateClick,
        onDisabledItemClick: () => {
          if (isMobileOrTablet && accountStatus?.subscription === PlanTypes.STANDARD) {
            openManageSubscriptionModal();
          }
        },
      },
      {
        title: 'Autofix',
        icon: getDraftsIcon(DraftType.AUTOFIX, !isAutofixEnabled),
        tooltipText: getDraftTooltipText(
          DraftType.AUTOFIX,
          'Professional Summary',
          shouldDisableAutofix,
          drafts.length === maxDrafts,
          maxDrafts,
          !isAutofixEnabled ? <DefaultDisabledTooltipContent /> : undefined,
        ),
        disabled: shouldDisableAutofix,
        onClick: onAutofixClick,
        onDisabledItemClick: () => {
          if (isMobileOrTablet && accountStatus?.subscription === PlanTypes.STANDARD) {
            openManageSubscriptionModal();
          }
        },
      },
    ];
  }, [
    accountStatus?.subscription,
    drafts.length,
    isAutofixEnabled,
    isGenerateEnabled,
    isLoading,
    isMobileOrTablet,
    latestWorkExperiences?.description,
    maxDrafts,
    onAutofixClick,
    onGenerateClick,
    openManageSubscriptionModal,
    professionalSummary,
  ]);

  return (
    // Add form element with onSubmit
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <form onSubmit={(e) => e.preventDefault()}>
      <Stack gap="48px">
        <Stack gap="8px">
          <Stack gap="4px">
            <InputLabel
              htmlFor="professional_summary"
              sx={{
                maxHeight: '24px',
                '& .MuiFormLabel-asterisk': {
                  color: 'error.main',
                  paddingLeft: 0.5,
                  fontSize: '12px',
                },
              }}
              required
            >
              <Typography variant="label2" color="text.primary">
                Professional Summary
              </Typography>
            </InputLabel>
            <FormHelperText>
              <Typography variant="body3">
                Tailor it to the job you apply for. Highlight your relevant experience, skills, and
                achievements that demonstrate your suitability for the position.
              </Typography>
            </FormHelperText>
          </Stack>
          <RichTextEditor
            html={professionalSummary}
            toolbarExternalControls={toolbarExternalControls}
            onChange={onEditorChange}
            onEditorRefChange={onRichTextEditorRef}
            autoFocus
            disabled={isLoading}
          />
        </Stack>

        <DraftsManager
          sectionTitle="Professional Summary"
          onAppendDraft={onAppendDraft}
          onReplaceDraft={onReplaceDraft}
        />

        {/* TODO: pass isLoading */}
        <FormActions onSubmit={onSubmit} />
      </Stack>
    </form>
  );
};

export default SummaryEditor;
