import { isNotNil } from 'ramda';

import type { ResponseStatus } from '../interface';
import http from './http';

export enum JobDescriptionTypes {
  JOBXRAYS = 'jobXrays',
  JOBDESCRIPTIONS = 'jobDescriptions',
}

export enum JobKeys {
  JOBS = '/jobs',
}

export enum JobProgressSource {
  JOB_XRAY = 'job_xray',
  AI_CUSTOMIZE = 'ai_customize',
  MATCH_SCORE = 'match_score',
  AI_GENERATE = 'ai_generate',
}

/**
 * Represents the result of a job description X-ray operation.
 *
 * @property {string} _id - The unique identifier of the job description X-ray.
 * @property {string} created_at - The creation timestamp of the job description X-ray.
 * @property {string} error - The error message, if any, associated with the job description X-ray.
 * @property {JobDescriptionXRay} job-description-xray - The job description X-ray details.
 * @property {number} progress - The progress percentage of the job description X-ray.
 * @property {string} status - The status of the job description X-ray.
 * @property {string} updated_at - The last update timestamp of the job description X-ray.
 * @property {number} used_for_score - The score used for the job description X-ray.
 * @property {string} user_id - The ID of the user associated with the job description X-ray.
 * @property {JobProgressSource} [source] - The source of the job description X-ray.
 * @property {string} [source_id] - The source ID of the job description X-ray.
 */
export interface JobDescriptionXRayListResult {
  _id: string;
  created_at: string;
  error: string;
  'job-description-xray': JobDescriptionXRay;
  progress: number;
  status: string;
  updated_at: string;
  used_for_score: number;
  user_id: string;
  source?: JobProgressSource;
  source_id?: string;
}

/**
 * Represents a detailed job description with various attributes.
 *
 * @interface JobDescriptionXRay
 *
 * @property {string} _id - Unique identifier for the job description.
 * @property {string} job_description_id - Identifier for the job description.
 * @property {string} created_at - Timestamp when the job description was created.
 * @property {string} updated_at - Timestamp when the job description was last updated.
 * @property {string} city - City where the job is located.
 * @property {string} company - Company offering the job.
 * @property {string} country - Country where the job is located.
 * @property {string} employment_type - Type of employment (e.g., full-time, part-time).
 * @property {string} occupation - Occupation or job title.
 * @property {string[]} responsibilities - List of responsibilities associated with the job.
 * @property {string} salary_range - Salary range for the job.
 * @property {string} seniority - Seniority level required for the job.
 * @property {string[]} skills - List of skills required for the job.
 * @property {Record<string, boolean>} responsibilities_keywords - Keywords related to job responsibilities.
 * @property {Record<string, boolean>} skills_keywords - Keywords related to job skills.
 * @property {string[]} special_requirements - Special requirements for the job.
 * @property {string} state - State where the job is located.
 * @property {string} title - Title of the job.
 * @property {string} work_arrangement - Work arrangement (e.g., remote, on-site).
 * @property {string[]} years_of_experience - Required years of experience for the job.
 * @property {string} job_description - Detailed description of the job.
 */
export interface JobDescriptionXRay {
  _id: string;
  job_description_id: string;
  created_at: string;
  updated_at: string;
  city: string;
  company: string;
  country: string;
  employment_type: string;
  occupation: string;
  responsibilities: string[];
  salary_range: string;
  seniority: string;
  skills: string[];
  responsibilities_keywords: Record<string, boolean>;
  skills_keywords: Record<string, boolean>;
  special_requirements: string[];
  state: string;
  title: string;
  work_arrangement: string;
  years_of_experience: string[];
  job_description: string;
}

/**
 * Retrieves all job descriptions with optional status filtering.
 *
 * @function
 * @async
 * @param {ResponseStatus} [status] - Optional status to filter job descriptions.
 * @returns {Promise<{ result: JobDescriptionXRayListResult[]; status: { status: ResponseStatus; message: string; }; }>}
 * A promise that resolves to an object containing the list of job descriptions and status information.
 * @throws {Error} If the request fails or the response contains errors.
 * @remarks This function uses the `isNotNil` utility from `ramda` to check if the status is provided.
 * If the status is provided, it appends it as a query parameter to the request URL.
 * The function makes an HTTP GET request to the `/job-description` endpoint.
 * If the response status is 'success', it resolves the promise with the response data.
 * Otherwise, it rejects the promise with an error.
 */
export const getAllJobXRays: {
  (): Promise<{
    result: JobDescriptionXRayListResult[];
    status: {
      status: ResponseStatus;
      message: string;
    };
  }>;
  // Function signature with 1 optional argument
  (status?: ResponseStatus): Promise<{
    result: JobDescriptionXRayListResult[];
    status: {
      status: ResponseStatus;
      message: string;
    };
  }>;
} = async (status?: ResponseStatus) => {
  try {
    const reqUrl = isNotNil(status) ? `/job-description?status=${status}` : '/job-description';
    const res = await http.get(reqUrl);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Uploads a job description to the server.
 *
 * @param {string} jobDescription - The job description to be uploaded.
 * @returns {Promise<JobDescriptionXRay>} A promise that resolves to the uploaded job description data.
 * @throws {Error} If the job description is not provided or if the server returns an error.
 */
export const uploadJobDescription = async (jobDescription: string): Promise<JobDescriptionXRay> => {
  if (!jobDescription) {
    return Promise.reject(new Error('Job Description is required'));
  }

  try {
    const res = await http.post('/job-description', { job_description: jobDescription });
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res?.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Fetches the X-Ray details of a job description by its ID.
 *
 * @param {string} jobDescId - The ID of the job description to fetch the X-Ray details for.
 * @returns {Promise<JobDescriptionXRay>} A promise that resolves to the job description X-Ray details.
 * @throws {Error} If the request fails or the response contains errors.
 */
export const getJobXRay = async (jobDescId: string): Promise<JobDescriptionXRay> => {
  try {
    const res = await http.get(`/job-description/xray/${jobDescId}`, {
      timeout: 5000, // Set the timeout to 5 seconds (5000 milliseconds)
    });
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Fetches the raw job description for a given job description ID.
 *
 * @param {string} jobDescId - The ID of the job description to fetch.
 * @returns {Promise<any>} A promise that resolves with the raw job description data if the request is successful,
 *                         or rejects with an error if the request fails.
 *
 * @throws {Error} If the request fails or the response contains errors.
 */
export const getRawJobDescription = async (jobDescId: string) => {
  try {
    const res = await http.get(`/job-description/raw/${jobDescId}`, {
      timeout: 5000, // Set the timeout to 5 seconds (5000 milliseconds)
    });
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Deletes a job description by its ID.
 *
 * @param {string} jobDescId - The ID of the job description to delete.
 * @returns {Promise<any>} A promise that resolves with the deleted job description data if the deletion is successful,
 * or rejects with an error if the deletion fails.
 * @throws {Error} If the HTTP request fails or the response indicates an error.
 */
export const deleteJobDescription = async (jobDescId: string) => {
  try {
    const res = await http.delete(`/job-description/delete/${jobDescId}`);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};
