import { useState } from 'react';
import type { ChangeEvent } from 'react';
import { useForm } from 'react-hook-form';

import type { SxProps } from '@mui/material';
import { FormHelperText, Stack } from '@mui/material';

import theme from '../../theme/theme';
import JobDescriptionExample from '../SelectJobDescription/components/JobDescriptionExample';
import InfoPopup from '../common/InfoPopup';
import type { InputLabelProps } from '../common/InputLabel';
import InputLabel from '../common/InputLabel';
import TextField from '../common/TextField';
import TextWrappedLinkButton from '../common/TextWrappedLinkButton';

interface JobDescriptionInputProps {
  inputLabelProps?: InputLabelProps;
  onUpdateDescription: (event: ChangeEvent<HTMLInputElement>) => void;
  sx?: SxProps;
}

/**
 * `JobDescriptionInput` component renders an input field where users can paste or type a job description.
 * It provides structured form control with a label, helper text, and an job description example popup.
 * The component integrates with `react-hook-form` for validation and error handling, and uses Material-UI for styling and layout.
 *
 * @component
 * @param {JobDescriptionInputProps} props - Properties passed to configure `JobDescriptionInput`.
 * @param {InputLabelProps} [props.inputLabelProps] - Custom props for configuring the label element.
 * @param {Function} props.onUpdateDescription - A function to handle changes in the input field.
 * @param {SxProps} [props.sx] - Optional custom styles applied to the component container.
 *
 * @returns {JSX.Element} A rendered `JobDescriptionInput` component.
 *
 * @example
 * const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
 *   console.log(event.target.value);
 * };
 *
 * <JobDescriptionInput onUpdateDescription={handleDescriptionChange}
 *   inputLabelProps={{ label: 'Job Description', required: true }}
 *   sx={{ marginTop: 2 }}
 * />
 *
 * @remarks
 * - The input field is limited to 16,384 characters to prevent excessively long descriptions.
 * - The helper text explains what to input, and clicking "Example" opens a popup which render an Example Job Description.
 * - Displays an error message if the description field is left empty.
 */
const JobDescriptionInput = ({
  inputLabelProps,
  onUpdateDescription,
  sx,
}: JobDescriptionInputProps) => {
  const {
    register,
    formState: { errors },
  } = useForm();
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const closePopup = () => {
    setShowPopup(false);
  };

  const openPopup = () => {
    setShowPopup(true);
  };

  return (
    <Stack sx={sx}>
      {/* Label for the Job Description input field with a "required" indicator */}
      <InputLabel htmlFor="description" label="Job Description" required {...inputLabelProps} />

      {/* Helper text displayed below the label, with a clickable example link */}
      <FormHelperText
        sx={{
          marginTop: theme.spacing(0),
          marginBottom: theme.spacing(1),
          paddingRight: { md: 2 },
        }}
      >
        <TextWrappedLinkButton
          variant="body3"
          text={{
            startingText:
              'Copy and paste the entire job description from the job posting, including the company name and job title. You don’t have to clean up the text.',
            linkText: 'Example',
            endingText: '',
          }}
          onClick={openPopup}
        />
      </FormHelperText>

      {/* InfoPopup displays an example of a job description to assist users in understanding the expected input format */}
      <InfoPopup
        content={{
          title: 'Example Job Posting from LinkedIn',
          description: <JobDescriptionExample />,
        }}
        open={showPopup}
        onOpen={openPopup}
        onClose={closePopup}
        drawerButtonType="close"
      />

      {/* Multiline input for the job description, integrated with `react-hook-form` for validation */}
      <TextField
        id="description"
        type="textarea"
        {...register('description', {
          required: 'Description is required',
        })}
        inputProps={{
          maxLength: 16384, // Limit input length to prevent excessively long descriptions
        }}
        multiline
        minRows={12}
        maxRows={20}
        error={!!errors.description} // Display error state if the description is missing
        helperText={errors.description?.message?.toString()}
        onChange={onUpdateDescription}
        fullWidth
      />
    </Stack>
  );
};

export default JobDescriptionInput;
