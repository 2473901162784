import { Global } from '@emotion/react';
import type { SwipeableDrawerProps as MuiSwipeableDrawerProps, StackProps } from '@mui/material';
import { Box, SwipeableDrawer as MuiSwipeableDrawer, Stack } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

// The height of the swipeable area for the drawer.
const drawerBleeding = 56;

// Styled component for the content inside the drawer.
export const DrawerContent = styled(Stack)({
  height: '100%',
  padding: 16,
  textAlign: 'center',
  alignItems: 'center',
});

// Styled component for the puller handle, providing visual feedback for swipe gestures.
export const Puller = styled(Box)(({ theme }) => ({
  width: 54,
  height: 4,
  backgroundColor: theme.palette.text.tertiary,
  borderRadius: 12,
}));

/**
 * Extended props for the SwipeableDrawer component, allowing for additional customization.
 * @typedef {Object} SwipeableDrawerProps
 * @property {StackProps} [contentProps] - Props for customizing the `DrawerContent`.
 */
interface SwipeableDrawerProps extends MuiSwipeableDrawerProps {
  contentProps?: StackProps;
}

/**
 * SwipeableDrawer Component
 *
 * This component renders a swipeable drawer with a bottom anchor. It uses Material-UI's SwipeableDrawer
 * and allows for customization of the drawer's styles and content.
 *
 * ### Features
 * - Swipeable drawer that opens from the bottom.
 * - Customizable content with `DrawerContent`.
 * - Includes a `Puller` for user feedback on swipe interactions.
 * - Supports additional styles via `sx` and `contentProps`.
 *
 * @param {SwipeableDrawerProps} props - The properties for the SwipeableDrawer component.
 * @param {object} [props.contentProps] - Additional props for the `DrawerContent` (Stack) component.
 * @param {boolean} props.open - Controls whether the drawer is open.
 * @param {function} props.onOpen - Callback triggered when the drawer is opened.
 * @param {function} props.onClose - Callback triggered when the drawer is closed.
 * @param {ReactNode} props.children - The content to be displayed inside the drawer.
 * @param {object} [props.sx] - Custom styles to apply to the root `SwipeableDrawer` component.
 * @param {object} [props.restProps] - Any additional props to pass to the Material-UI `SwipeableDrawer`.
 * @returns {JSX.Element} The swipeable drawer component.
 */
const SwipeableDrawer = ({
  sx,
  open,
  onOpen,
  onClose,
  children,
  contentProps,
  ...restProps
}: SwipeableDrawerProps) => {
  // Access the current theme for consistent styling.
  const theme = useTheme();

  return (
    <>
      {/* Global styles for the drawer, overriding Material-UI defaults for rounded corners and overflow. */}
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: 'auto',
            overflow: 'visible',
            borderRadius: '12px 12px 0px 0px',
          },
        }}
      />

      {/* Material-UI SwipeableDrawer component with bottom anchor and custom styles. */}
      <MuiSwipeableDrawer
        anchor="bottom"
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        swipeAreaWidth={drawerBleeding} // Height of the swipeable area to open the drawer.
        disableSwipeToOpen // Prevents swipe gestures from opening the drawer.
        ModalProps={{
          keepMounted: true, // Ensures the drawer remains in the DOM when closed for better performance.
        }}
        sx={{ zIndex: theme.zIndex.drawer + 1, ...sx }}
        {...restProps}
      >
        {/* Content inside the drawer, styled using DrawerContent. */}
        <DrawerContent {...contentProps}>{children}</DrawerContent>
      </MuiSwipeableDrawer>
    </>
  );
};

export default SwipeableDrawer;
