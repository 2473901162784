import type { SxProps } from '@mui/material';
import { Modal as MuiModal } from '@mui/material';
import type { ModalProps as MuiModalProps } from '@mui/material/Modal';
import Stack from '@mui/material/Stack';

/**
 * Props for the custom Modal component.
 * Extends Material-UI's MuiModalProps and adds custom style props.
 *
 * @property {SxProps} [sx] - Additional styling applied to the outer Modal wrapper.
 * @property {SxProps} [contentSx] - Styling applied to the inner Stack containing modal content.
 * @property {React.ReactNode} children - The content to be displayed within the modal.
 */
interface ModalProps extends MuiModalProps {
  sx?: SxProps;
  contentSx?: SxProps;
}

/**
 * A reusable Modal component built using Material-UI's Modal.
 * Provides a customizable modal layout with consistent styles and flexible content.
 * @param {SxProps} [sx] - Additional styling applied to the outer `Modal` wrapper.
 * @param {SxProps} [contentSx] - Styling applied to the inner `Stack` containing modal content.
 * @param {React.ReactNode} children - The content to be displayed within the modal.
 * @param {...MuiModalProps} modalProps - Additional props for Material-UI's `Modal`.
 * @returns {JSX.Element} The rendered Modal component.
 */
const Modal = ({ sx, contentSx, children, ...modalProps }: ModalProps): JSX.Element => (
  <MuiModal
    // Custom styles for the outer modal wrapper to center-align content.
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...sx,
    }}
    {...modalProps} // Pass all additional props to the Material-UI Modal.
    className="desktop-modal"
  >
    <Stack
      // Default styling for the modal content wrapper.
      sx={{
        gap: 5,
        backgroundColor: 'common.white', // Ensure a white background.
        borderRadius: 1.5, // Rounded corners for the modal.
        padding: 2.5, // Internal padding for the modal content.
        minWidth: '18.75rem', // Minimum width for responsiveness.
        width: '26rem', // Default width for the modal.
        maxHeight: '80vh', // Constrain modal height for vertical scrolling.
        height: 'fit-content', // Adjust height based on content.
        ...contentSx, // Merge additional custom styles for content.
      }}
      className="desktop-modal-content"
    >
      {/* Render the modal's children (content). */}
      {children}
    </Stack>
  </MuiModal>
);

export default Modal;
