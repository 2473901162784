import type { LexicalEditor } from 'lexical';
import type { MutableRefObject } from 'react';
import { useState } from 'react';

import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { TRANSFORMERS } from '@lexical/markdown';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { Box, useTheme } from '@mui/material';

import { hasTextInsideHtml } from '../../utils';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
import ComposeButtonPlugin from './plugins/ComposeButtonPlugin';
import EditorStatePlugin from './plugins/EditorStatePlugin';
import HtmlPlugin from './plugins/HtmlPlugin';
import LexicalEditorRefPlugin from './plugins/LexicalEditorRefPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import { type ToolbarExternalControls, ToolbarPlugin } from './plugins/ToolbarPlugin';
import './styles/styles.css';
// import TreeViewPlugin from './plugins/TreeViewPlugin';
import ExampleTheme from './themes/ExampleTheme';

/**
 * Placeholder component that displays a placeholder text inside a div with a specific class.
 *
 * @param {Object} props - The props object.
 * @param {string} props.placeholder - The placeholder text to display. Defaults to an empty string.
 * @returns {JSX.Element} A div element containing the placeholder text.
 */
const Placeholder = ({ placeholder = '' }: { placeholder: string }) => (
  <div className="editor-placeholder">{placeholder}</div>
);

/**
 * Configuration object for the Rich Text Editor.
 *
 * @constant
 * @type {object}
 * @property {boolean} editable - Determines if the editor is editable.
 * @property {string} namespace - The namespace for the editor.
 * @property {object} theme - The theme for the editor.
 * @property {function} onError - Callback function to handle errors during update.
 * @property {Array} nodes - Array of custom nodes to be used in the editor.
 */
const EDITOR_CONFIG = {
  editable: true,
  namespace: 'rich-text-editor',
  // The editor theme
  theme: ExampleTheme,
  // Handling of errors during update
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError(error: any) {
    // throw error;
    // eslint-disable-next-line no-console
    console.error({ error });
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
  ],
};

/**
 * Props for the RichTextEditor component.
 *
 * @property ref - A reference to the LexicalEditor instance. It can be a callback ref or a mutable ref object.
 * @property placeholder - An optional placeholder text to display when the editor is empty.
 * @property html - An optional HTML string to set the initial content of the editor.
 * @property autoFocus - An optional boolean to determine if the editor should auto-focus on mount.
 * @property disabled - An optional boolean to disable the editor.
 * @property isComposing - An optional boolean to indicate if the editor is in composition mode.
 * @property toolbarExternalControls - An optional object to provide external controls for the toolbar.
 * @property onChange - A callback function that is called when the content of the editor changes.
 * @property onCompose - An optional callback function that is called when the editor enters composition mode.
 * @property onEditorRefChange - An optional callback function that is called when the editor reference changes.
 */
interface RichTextEditorProps {
  ref?:
    | React.RefCallback<LexicalEditor>
    | MutableRefObject<LexicalEditor | null | undefined>
    | undefined;
  placeholder?: string;
  html?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  isComposing?: boolean;
  toolbarExternalControls?: ToolbarExternalControls;
  onChange: (description: string) => void;
  onCompose?: () => void;
  onEditorRefChange?: (editor: LexicalEditor) => void;
}

// TODO: Wrap in forward ref
/**
 * RichTextEditor component provides a rich text editing component with various plugins and configurations.
 *
 * @param {RichTextEditorProps} props - The properties for the RichTextEditor component.
 * @param {React.Ref} props.ref - A reference to the editor instance.
 * @param {string} [props.placeholder='Enter some text...'] - Placeholder text to display when the editor is empty.
 * @param {string} props.html - Initial HTML content to load into the editor.
 * @param {boolean} [props.autoFocus=false] - Whether the editor should automatically focus on mount.
 * @param {boolean} [props.disabled=false] - Whether the editor should be disabled.
 * @param {boolean} [props.isComposing=false] - Whether the editor is in composing mode.
 * @param {any} props.toolbarExternalControls - External controls for the toolbar.
 * @param {Function} props.onChange - Callback function to handle changes in the editor content.
 * @param {Function} props.onCompose - Callback function to handle compose actions.
 * @param {Function} props.onEditorRefChange - Callback function to handle changes in the editor reference.
 *
 * @returns {JSX.Element} The RichTextEditor component.
 */
const RichTextEditor = ({
  ref,
  placeholder = 'Enter some text...',
  html,
  autoFocus = false,
  disabled = false,
  isComposing = false,
  toolbarExternalControls,
  onChange,
  onCompose,
  onEditorRefChange,
}: RichTextEditorProps) => {
  const [isFocused, setIsFocused] = useState(autoFocus);
  const theme = useTheme();

  const onTextChange = (htmlText: string) => {
    const text = hasTextInsideHtml(htmlText) ? htmlText : '';
    onChange(text);
  };

  return (
    <Box
      sx={{
        '.editor-container': {
          background: disabled ? theme.palette.background.mediumLight : 'white',
          opacity: disabled ? 0.75 : 1,
          pointerEvents: disabled ? 'none' : 'auto',
          color: disabled ? theme.palette.text.tertiary : theme.palette.text.primary,
        },
        '.editor-inner': {
          background: disabled ? theme.palette.background.mediumLight : 'white',
        },
      }}
    >
      <LexicalComposer initialConfig={EDITOR_CONFIG}>
        <div className={`editor-container ${isFocused ? 'focused' : ''}`}>
          <ToolbarPlugin externalControls={toolbarExternalControls} disabled={disabled} />
          <div className="editor-inner">
            <RichTextPlugin
              contentEditable={
                <ContentEditable
                  className="editor-input"
                  onFocus={() => {
                    setIsFocused(true);
                  }}
                  onBlur={() => {
                    setIsFocused(false);
                  }}
                />
              }
              placeholder={<Placeholder placeholder={placeholder} />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <LexicalEditorRefPlugin editorRef={ref} onEditorRefChange={onEditorRefChange} />
            <HistoryPlugin />
            {/* <TreeViewPlugin /> */}
            {autoFocus && <AutoFocusPlugin />}
            <TabIndentationPlugin />
            <CodeHighlightPlugin />
            <ListPlugin />
            <LinkPlugin />
            <AutoLinkPlugin />
            <ListMaxIndentLevelPlugin maxDepth={2} />
            <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
            <HtmlPlugin initialHtml={html} onHtmlChange={onTextChange} />
            <EditorStatePlugin />
          </div>
          <ComposeButtonPlugin onCompose={onCompose} isComposing={isComposing} />
        </div>
      </LexicalComposer>
    </Box>
  );
};

export default RichTextEditor;
