import type React from 'react';
import type { FieldErrors, FieldValues } from 'react-hook-form';

import { Stack } from '@mui/material';

import useResponsiveDevice from '../../../../hooks/useResponsiveDevice';
import { isNotNilOrEmpty } from '../../../../utils';
import Button from '../../../common/Button';

/**
 * Props for the ComposeActions component.
 */
interface ComposeActionsProps {
  /**
   * The current step of the modal workflow.
   * Determines the label of the cancel/back button and submit button.
   */
  step: number;

  /**
   * Errors object from `react-hook-form` to check form validation.
   */
  errors: FieldErrors<FieldValues>;

  /**
   * Indicates whether the form is valid.
   */
  isValid: boolean;

  /**
   * Callback function to handle the Cancel or Back button click.
   */
  handleCancelButtonClick: () => void;

  /**
   * Callback function to handle the Next or Compose button click.
   */
  handleSubmitButtonClick: (e?: React.BaseSyntheticEvent) => void;
}

/**
 * ComposeActions Component
 *
 * This component represents the action buttons section of the `ComposeWorkExpModal` component.
 * It dynamically updates button labels and behavior based on the current step.
 *
 * @component
 *
 * @param {ComposeActionsProps} props - The properties for the component.
 * @param {number} props.step - The current step in the modal workflow.
 * @param {FieldErrors<FieldValues>} props.errors - Errors object from `react-hook-form`.
 * @param {boolean} props.isValid - Indicates if the form is valid.
 * @param {() => void} props.handleCancelButtonClick - Callback for the Cancel/Back button.
 * @param {(e?: React.BaseSyntheticEvent) => void} props.handleSubmitButtonClick - Callback for the Next/Compose button.
 *
 * @returns {JSX.Element} The rendered ComposeActions component.
 *
 * @example
 * Example Usage:
 * <ComposeActions
 *   step={1}
 *   errors={errors}
 *   isValid={isValid}
 *   handleCancelButtonClick={handleCancelButtonClick}
 *   handleSubmitButtonClick={handleSubmitButtonClick}
 * />
 *
 * @remarks
 * This component is used in the `ComposeWorkExpModal` component to render the action buttons.
 * The button labels and behavior are dynamically updated based on the current step.
 */
const ComposeActions: React.FC<ComposeActionsProps> = ({
  step,
  errors,
  isValid,
  handleCancelButtonClick,
  handleSubmitButtonClick,
}) => {
  // Hook to determine if the device is mobile
  const { isMobile } = useResponsiveDevice();

  return (
    <Stack
      sx={{
        justifyContent: 'space-between',
        flexDirection: 'row',
        gap: 1.5, // Spacing between buttons
      }}
    >
      {/* Cancel/Back button */}
      <Button
        onClick={handleCancelButtonClick}
        variant="outlined"
        sx={{
          width: { xs: '30%', sm: 'max-content' }, // Responsive width
          ...(isMobile ? { padding: 2 } : {}), // Adjust padding for mobile devices
        }}
      >
        {step === 1 ? 'Cancel' : 'Back'}
      </Button>

      {/* Next/Compose button */}
      <Button
        type="button"
        disabled={isNotNilOrEmpty(errors) || !isValid} // Disable button if there are errors or form is invalid
        onClick={handleSubmitButtonClick}
        sx={{
          width: { xs: '70%', sm: 'max-content' }, // Responsive width
          ...(isMobile ? { padding: 2 } : {}), // Adjust padding for mobile devices
        }}
      >
        {step === 1 ? 'Next' : 'Compose '}
      </Button>
    </Stack>
  );
};

export default ComposeActions;
