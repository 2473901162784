import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import { Stack, TextField, Typography } from '@mui/material';

import type { PatentInfo } from '../../../interface';
import FormActions from '../../Form/FormActions';
import InputLabel from '../../common/InputLabel';

interface PatentEditorProps {
  onUpdate: (patent: PatentInfo, method: string) => void;
  defaultPatent?: PatentInfo;
}

const PatentEditor = ({ onUpdate, defaultPatent }: PatentEditorProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const method = defaultPatent?._id === '' ? 'add' : 'edit';

  const onSubmit: SubmitHandler<{ title: string; number: string }> = (patentData) => {
    onUpdate(
      {
        _id: defaultPatent?._id || '',
        ...patentData,
      },
      method,
    );
  };

  const onSaveClick = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleSubmit(onSubmit as any)();
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Stack gap={3}>
        <Stack gap={1}>
          <InputLabel htmlFor="title" label="Patent Title" required />
          {/* TODO: Use common TextField component */}
          <TextField
            id="title"
            placeholder="Method to terraform Mars"
            {...register('title', {
              required: 'Enter a patent title',
            })}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid',
                  borderColor: 'border.light',
                  borderRadius: '8px',
                  padding: '10px',
                },
              },
            }}
            inputProps={{
              maxLength: 1024,
            }}
            defaultValue={defaultPatent?.title}
            variant="outlined"
            error={!!errors.title}
            fullWidth
          />
          {!!errors?.title && (
            <Typography variant="assistive" color="error">
              {errors?.title?.message?.toString()}
            </Typography>
          )}
        </Stack>
        <Stack gap={1}>
          <InputLabel htmlFor="number" label="Patent or Application Number" />
          <TextField
            id="number"
            placeholder="US Patent No. 123456789"
            {...register('number')}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid',
                  borderColor: 'border.light',
                  borderRadius: '8px',
                  padding: '10px',
                },
              },
            }}
            inputProps={{
              maxLength: 1024,
            }}
            defaultValue={defaultPatent?.number}
            variant="outlined"
            fullWidth
          />
        </Stack>
        <FormActions onSubmit={onSaveClick} />
      </Stack>
    </form>
  );
};

export default PatentEditor;
