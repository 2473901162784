import type { ChangeEvent } from 'react';
import { useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import JobDescriptionInput from '../../components/JobDescriptionInput';
import ProgressManager from '../../components/ProgressManager';
import { ProgressScreenType } from '../../components/ProgressManager/constants';
import BreadCrumbs from '../../components/common/BreadCrumbs';
import Button from '../../components/common/Button';
import { useSnackbar } from '../../contexts/snackbar';
import useResponsiveDevice from '../../hooks/useResponsiveDevice';
import RoutePaths from '../../routes/RoutePaths';
import { JobDescriptionTypes, uploadJobDescription } from '../../services/jobDescriptions';
import { useAppBarStore } from '../../stores/AppBarStore';
import theme from '../../theme/theme';
import { isNotNilOrEmpty } from '../../utils';

/**
 * Component to import a new job description.
 *
 * This component allows users to create a job description by entering text and uploading it.
 * It handles the form state, validation, and submission for creating a job description.
 *
 * @component
 *
 * @returns {JSX.Element} The rendered CreateJob component.
 *
 * @example
 * Example Usage:
 * <CreateJob />
 *
 * @remarks
 * This component uses various hooks and context providers to manage the state and behavior of the job description creation process.
 */
const CreateJob = (): JSX.Element => {
  const [importJDText, setImportJDText] = useState<string>('');
  const { isMobileOrTablet } = useResponsiveDevice();
  const location = useLocation();
  const navigate = useNavigate();
  const [jobDescriptionId, setJobDescriptionId] = useState<string>(
    location?.state?.jobDescriptionId || '',
  );

  const { showSnackbar } = useSnackbar();
  const [isShowingProgress, setIsShowingProgress] = useState(
    isNotNilOrEmpty(location?.state?.jobDescriptionId),
  );

  const BreadCrumbLinks = isMobileOrTablet
    ? [
        {
          label: 'Back',
          href: '/',
        },
      ]
    : [
        {
          label: 'Jobs',
          href: RoutePaths.JOBS,
        },
        {
          label: 'Add',
          href: RoutePaths.CREATE_JOB,
        },
      ];

  const { setAppBar, resetToDesktopAppBar, setInitialState } = useAppBarStore();

  useLayoutEffect(() => {
    if (isMobileOrTablet) {
      setAppBar('Add Job', null);
    } else {
      resetToDesktopAppBar();
    }
    return () => {
      setInitialState(isMobileOrTablet);
    };
  }, [isMobileOrTablet, resetToDesktopAppBar, setAppBar, setInitialState]);

  // query client & mutations
  const queryClient = useQueryClient();

  const { isLoading: isJdUploading, mutate: uploadJD } = useMutation({
    retry: 3,
    mutationFn: uploadJobDescription,
    onSuccess: (jdUploadData) => {
      queryClient.invalidateQueries([JobDescriptionTypes.JOBXRAYS]); // invalidate, so that updated resumes are fetched
      setJobDescriptionId(jdUploadData._id);
      setIsShowingProgress(true);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      showSnackbar(
        'error',
        error.response?.data?.errors || 'Failed to upload Job Description. Please try again.',
      );
    },
  });

  /**
   * Event handler for the job description import text field change event.
   *
   * @param {ChangeEvent<HTMLInputElement>} event - The change event object.
   */
  const handleJDImportTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    setImportJDText(event.target.value);
  };

  const onClickAdd = () => {
    uploadJD(importJDText);
  };

  const onClickBack = () => {
    navigate(-1);
  };

  return (
    // create job description page
    <Stack
      sx={{
        height: { xs: '90%', sm: '100%' },
        width: '100%',
        maxWidth: '120rem',
        padding: {
          xs: `${theme.spacing(3)} ${theme.spacing(1.5)}`,
          md: `${theme.spacing(3)}`,
        },
        boxSizing: 'border-box',
        gap: { xs: 1.5, md: 4 },
      }}
    >
      {!isShowingProgress ? (
        <>
          <Stack sx={{ gap: 0.5 }}>
            <BreadCrumbs links={BreadCrumbLinks} />
            {!isMobileOrTablet && (
              <Typography variant="body2" sx={{ paddingLeft: 3.75 }}>
                Create a Job X-Ray that highlights requirements of the job.
              </Typography>
            )}
          </Stack>
          <Stack
            sx={{
              paddingX: { md: 3.75 },
              gap: 4,
              height: '100%',
              justifyContent: 'space-between',
            }}
          >
            <JobDescriptionInput
              onUpdateDescription={handleJDImportTextFieldChange}
              inputLabelProps={{ label: 'Import a Job', required: false }}
            />
            {/* action buttons */}
            <Stack sx={{ gap: 1.5, flexDirection: 'row', justifyContent: 'flex-end' }}>
              <Button
                variant="outlined"
                onClick={onClickBack}
                sx={{
                  width: { xs: '30%', sm: 'fit-content' },
                  padding: {
                    xs: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
                    sm: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={onClickAdd}
                sx={{
                  width: { xs: '70%', sm: 'fit-content' },
                  padding: {
                    xs: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
                    sm: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
                  },
                }}
                disabled={isJdUploading || !importJDText}
                loading={isJdUploading}
              >
                Add
              </Button>
            </Stack>
          </Stack>
        </>
      ) : (
        <ProgressManager id={jobDescriptionId} progressScreenKey={ProgressScreenType.JDXray} />
      )}
    </Stack>
  );
};

export default CreateJob;
