import { isNotNil } from 'ramda';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation, useQuery } from '@tanstack/react-query';

import { useSnackbar } from '../../contexts/snackbar';
import { EventAction, EventCategory, logEvent } from '../../services/analytics';
import { getJobXRay } from '../../services/jobDescriptions';
import type { MissingInfoPayload } from '../../services/progress';
import { addMissingInfo, continueCreateTask, getProgressStatus } from '../../services/progress';
import type { JDKeywordOptions } from '../../services/resumes';
import {
  continueAICustomizeForMatchScoreFlow,
  continueAICustomizeResume,
} from '../../services/resumes';
import { useSideBarStore } from '../../stores/SideBarStore';
import type { MissingInfoContent } from '../../utils';
import {
  compareProgressStatus,
  getProgressScreenSnackbarMessage,
  processMissingFields,
} from '../../utils';
import MissingJobInfoForm from './components/MissingJobInfoForm';
import ProgressRenderer from './components/ProgressRenderer';
import SelectKeywordForm from './components/SelectKeywordsForm';
import {
  ProgressScreenType,
  ProgressStatusTypes,
  ScreenTypeEventSuccessLabel,
  progressContent,
} from './constants';

// TODO: update missinginfo component and service name
/**
 * Props for the ProgressManager component.
 */
interface ProgressManagerProps {
  progressScreenKey: ProgressScreenType;
  id: string;
  successEventLabel?: string;
}

/**
 * ProgressManager Component
 *
 * This component is responsible for displaying the progress of various tasks such as
 * resume creation, job match score calculation, and job x-ray generation.
 * It shows a progress indicator and handles success and error states.
 *
 * @component
 *
 * @param {ProgressManagerProps} props - The properties for the component.
 * @param {string} props.id - The ID of the entity for which progress is being tracked.
 * @param {ProgressScreenType} props.progressScreenKey - The key representing the type of progress screen.
 * @param {string} props.successEventLabel - The label for the success event to be logged.
 *
 * @returns {JSX.Element} The rendered ProgressManager component.
 *
 * @example
 * <ProgressManager
 *   id="12345"
 *   progressScreenKey={ProgressScreenType.AiCustomize}
 *   successEventLabel="AI Customized Resume Creation Successful"
 * />
 *
 * @remarks
 * This component uses various hooks and context providers to manage the state and behavior of the progress tracking.
 */
const ProgressManager = ({ progressScreenKey, id, successEventLabel }: ProgressManagerProps) => {
  const { hideAppBar, hideSideBar } = useSideBarStore();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [isProgressStatusError, setIsProgressStatusError] = useState<boolean>(false);
  const snackbarMessage = getProgressScreenSnackbarMessage(progressScreenKey);

  const [isProgressFetchingPaused, setIsProgressFetchingPaused] = useState(false);

  const [showMissingFieldsForm, setShowMissingFieldsForm] = useState(false);
  const [missingInfoContent, setMissingInfoContent] = useState<MissingInfoContent>({
    missingFields: undefined,
    missingFieldsEndpoint: '',
    jobDescriptionId: '',
    hasMissingFields: false,
  });

  const [showSelectKeywordsForm, setShowSelectKeywordsForm] = useState(false);
  const [selectKeywordsContent, setSelectKeywordsContent] = useState<JDKeywordOptions>({
    skills_Keywords: {},
    responsibilities_Keywords: {},
  });

  const [progressText, setProgressText] = useState(progressContent[progressScreenKey].progressText);

  useEffect(() => {
    hideAppBar();
    hideSideBar();
  }, [hideAppBar, hideSideBar]);

  /**
   * Fetches keywords related to skills and responsibilities for a given job description ID.
   *
   * This function makes an asynchronous call to retrieve job description details using the provided
   * job description ID. If the response contains skills and responsibilities keywords, it updates
   * the state with these keywords and displays a form to select keywords. Additionally, it pauses
   * the progress fetching after a short delay.
   *
   * @param {string} jobDescriptionId - The unique identifier for the job description.
   * @returns {Promise<void>} A promise that resolves when the operation is complete.
   */
  const fetchKeywordsToSelect = async (jobDescriptionId: string) => {
    const JDResponse = await getJobXRay(jobDescriptionId);

    if (JDResponse?.skills_keywords && JDResponse?.responsibilities_keywords) {
      setSelectKeywordsContent({
        skills_Keywords: JDResponse.skills_keywords,
        responsibilities_Keywords: JDResponse.responsibilities_keywords,
      });

      setShowSelectKeywordsForm(true);
      setTimeout(() => {
        setIsProgressFetchingPaused(true);
      }, 1000);
    }
  };

  const fetchProgressStatus = useCallback(
    async (_id: string, endpoint: string) => {
      const res = await getProgressStatus({ id: _id, endpoint });

      // TODO: error handling will be updated once the API is updated
      const hasError = [
        res?.data?.status,
        res?.data?.resume?.status,
        res?.data?.job_description?.status,
        res?.data?.match_score?.status,
        res?.data?.ai_customization?.status,
        res?.data?.ai_generation?.status,
      ].some((value) => value === 'error');

      setIsProgressStatusError(hasError);
      const missingInfo = processMissingFields(res?.data, progressScreenKey);
      setMissingInfoContent(missingInfo);

      // Enable popup when there is a pending status or the flow is create job xray
      if (
        missingInfo?.hasMissingFields &&
        (compareProgressStatus(res?.data, progressScreenKey, ProgressStatusTypes.PENDING) ||
          compareProgressStatus(res?.data, progressScreenKey, ProgressStatusTypes.MISSING))
      ) {
        // Enable popup form
        setShowMissingFieldsForm(true);
      } else if (
        compareProgressStatus(res?.data, progressScreenKey, ProgressStatusTypes.SELECT_KEYWORDS) ||
        (progressScreenKey === ProgressScreenType.JobMatchScore &&
          res?.data?.ai_customization?.status === ProgressStatusTypes.SELECT_KEYWORDS)
      ) {
        // If the status is select_keywords, show the popup to select keywords for the job description
        await fetchKeywordsToSelect(res?.data?.job_description?.id);
      } else if (res?.data?.progress === 100) {
        setIsProgressFetchingPaused(false);
        setTimeout(() => {
          showSnackbar('success', snackbarMessage.success);

          logEvent(
            EventCategory.FORM_SUBMISSION,
            EventAction.SUBMIT,
            successEventLabel || ScreenTypeEventSuccessLabel[progressScreenKey],
          );

          navigate(`${progressContent[progressScreenKey].redirectPath}/${id}`);
        }, 1000);
      }

      //  return progress data
      return res?.data;
    },
    [progressScreenKey, showSnackbar, snackbarMessage.success, successEventLabel, navigate, id],
  );

  const { data: progressStatus, isError: isProgressError } = useQuery({
    enabled: !!id && !isProgressFetchingPaused,
    queryKey: [progressContent[progressScreenKey].queryKey, id, 'status'],
    queryFn: () => fetchProgressStatus(id, progressContent[progressScreenKey].endpoint),
    refetchInterval: (data) => {
      // if the task is in progress, refetch every 5 seconds
      if (
        !isProgressStatusError &&
        !showMissingFieldsForm &&
        isNotNil(data) &&
        !compareProgressStatus(data, progressScreenKey, ProgressStatusTypes.DONE)
      ) {
        if (progressScreenKey === ProgressScreenType.JobMatchScore) {
          if (data?.ai_customization?.progress < 100) {
            setProgressText(progressContent[progressScreenKey].aiProgressText || '');
          } else {
            setProgressText(progressContent[progressScreenKey].progressText);
          }
        }
        return 5000;
      }
      return false;
    },
    retry: 2,
  });

  // useQuery will set isProgressError to true after 3 consecutive failed calls.
  // If isProgressStatusError is true, then the error is returned by the status API call.
  // Otherwise, the error is due to a network issue.
  useEffect(() => {
    if (isProgressError) {
      showSnackbar('error', isProgressStatusError ? snackbarMessage.error : 'Something went wrong');
    }
  }, [isProgressError, isProgressStatusError, showSnackbar, snackbarMessage.error]);

  const { mutate: continueCreateTaskProgress, isLoading: isContinueProgressLoading } = useMutation({
    mutationFn: continueCreateTask,
    onSuccess: () => {
      setShowMissingFieldsForm(false);
      setIsProgressStatusError(false);
    },
    onError: () => {
      setIsProgressStatusError(false);
      showSnackbar(
        'info',
        'We encountered an issue processing your data. Please try resubmitting or contact support',
      );
    },
  });

  const { mutate: submitMissingInfo, isLoading: isSubmitMissingInfoLoading } = useMutation({
    mutationFn: addMissingInfo,
    onSuccess: () => {
      setShowMissingFieldsForm(false);
      setIsProgressStatusError(false);
    },
    onError: () => {
      showSnackbar(
        'info',
        'We encountered an issue processing your data. Please try resubmitting or contact support',
      );
    },
  });

  const { mutate: submitSelectKeywordsForAiCustomize, isLoading: isSubmitSelectKeywordsLoading } =
    useMutation<void, unknown, { keywords: JDKeywordOptions }>({
      mutationFn: async (data) => {
        if (progressStatus?.match_score && progressStatus?.ai_customization) {
          return continueAICustomizeForMatchScoreFlow({
            matchScoreId: id || '',
            keywords: data.keywords,
          });
        }

        return continueAICustomizeResume({
          resumeId: progressStatus?.ai_customization?.id || '',
          keywords: data.keywords,
        });
      },
      onSuccess: () => {
        setShowSelectKeywordsForm(false);
        setIsProgressFetchingPaused(false);
      },
      onError: () => {
        setIsProgressFetchingPaused(false);
        showSnackbar(
          'info',
          'We encountered an issue processing your data. Please try resubmitting or contact support',
        );
      },
    });

  const onSubmitKeywords = (data: JDKeywordOptions) => {
    submitSelectKeywordsForAiCustomize({
      keywords: data,
    });
  };

  const onSubmitMissingInfo = (data: MissingInfoPayload) => {
    // TODO: refactor this to use existing services pattern, and not use the endpoint directly - better maintainability
    const requestData = {
      endpoint: missingInfoContent.missingFieldsEndpoint,
      payload: data,
    };
    if (progressScreenKey === ProgressScreenType.JDXray) {
      submitMissingInfo(requestData);
    } else {
      let param = null;
      if (progressScreenKey === ProgressScreenType.AiCustomize) {
        param = 'ai_customize';
      } else if (progressScreenKey === ProgressScreenType.SampleResume) {
        param = 'ai_generate';
      }
      requestData.endpoint = `${requestData.endpoint}/${id}/continue${
        param ? `?task_type=${param}` : ''
      }`;
      continueCreateTaskProgress(requestData);
    }
    setIsProgressFetchingPaused(false);
  };

  const onCloseSelectKeywordsForm = () => {
    setShowSelectKeywordsForm(false);
    setIsProgressFetchingPaused(false);
  };

  return (
    <>
      <ProgressRenderer
        {...progressContent[progressScreenKey]}
        progressText={progressText}
        isError={isProgressStatusError || isProgressError}
        progress={progressStatus?.progress || 0}
      />

      <MissingJobInfoForm
        missingFields={missingInfoContent.missingFields}
        onSubmitMissingInfo={onSubmitMissingInfo}
        isLoading={isContinueProgressLoading || isSubmitMissingInfoLoading}
        open={showMissingFieldsForm}
      />

      <SelectKeywordForm
        open={showSelectKeywordsForm}
        options={selectKeywordsContent}
        isLoading={isSubmitSelectKeywordsLoading}
        onSubmit={onSubmitKeywords}
        onClose={onCloseSelectKeywordsForm}
      />
    </>
  );
};
export default ProgressManager;
