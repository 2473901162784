import type { InputLabelProps as MuiInputLabelProps, TypographyProps } from '@mui/material';
import { InputLabel as MuiInputLabel, Typography } from '@mui/material';

import theme from '../../theme/theme';

// TODO: improve this component and remove typographyProps
// Define the props for the InputLabel component
export interface InputLabelProps extends MuiInputLabelProps {
  label?: React.ReactNode;
  typographyProps?: TypographyProps;
}

/**
 * InputLabel Component
 *
 * A custom wrapper around Material-UI's `InputLabel` that integrates a `Typography` component
 * for enhanced styling and additional customization options.
 *
 * @component
 *
 * @param {React.ReactNode} [label] - The text or node to display as the label content.
 * @param {TypographyProps} [typographyProps] - Props to customize the inner `Typography` component.
 * @param {string} [htmlFor] - The ID of the input element this label is associated with.
 * @param {boolean} [required] - If true, indicates that the field is required and styles the label accordingly.
 * @param {SxProps} [sx] - Additional styles to apply to the `InputLabel` component.
 * @param {MuiInputLabelProps} restProps - Additional props inherited from Material-UI's `InputLabel`.
 *
 * @returns {JSX.Element} A styled input label with integrated typography and support for Material-UI props.
 *
 * @example
 * // Basic usage
 * <InputLabel htmlFor="username" label="Username" required />
 *
 * @example
 * // Custom typography styles
 * <InputLabel
 *   htmlFor="email"
 *   label="Email Address"
 *   typographyProps={{ variant: 'h6', color: 'primary.main' }}
 * />
 *
 * @example
 * // With additional styling and Material-UI props
 * <InputLabel
 *   htmlFor="password"
 *   label="Password"
 *   required
 *   sx={{ fontWeight: 'bold', fontSize: '1rem' }}
 *   typographyProps={{ variant: 'subtitle1', fontStyle: 'italic' }}
 * />
 */
const InputLabel = ({
  htmlFor,
  required,
  sx,
  label,
  typographyProps,
  ...restProps
}: InputLabelProps) => (
  <MuiInputLabel
    htmlFor={htmlFor}
    sx={{
      color: required ? 'error.main' : 'text.primary',
      '& .MuiFormLabel-asterisk': { paddingLeft: 0.5, fontSize: 12 },
      ...theme.typography.label2,
      ...sx,
    }}
    required={required}
    {...restProps}
  >
    <Typography variant="label2" color="text.primary" {...typographyProps}>
      {label}
    </Typography>
  </MuiInputLabel>
);

export default InputLabel;
