import { useLocation } from 'react-router-dom';

import RoutePaths from '../routes/RoutePaths';

/**
 * Determines if the current page is a tablet responsive page.
 * Tablet responsive pages include:
 * - Home page
 * - Job Matches Listing page
 * - Jobs X-Ray Listing page
 * - Resumes Listing page
 * - Create Job page
 * - Resume Editor pages
 *
 * @returns {boolean} - Returns true if the current page is a tablet responsive page, otherwise returns false.
 */
const useIsTabletResponsivePage = () => {
  const location = useLocation();
  const resumeEditorBasePath = `${RoutePaths.RESUME_EDIT}`.replace(':key', '');
  const tabletPaths = [
    RoutePaths.HOME,
    RoutePaths.JOBMATCHES,
    RoutePaths.JOBS,
    RoutePaths.RESUMES,
    RoutePaths.CREATE_JOB,
  ];
  return (
    tabletPaths.includes(location.pathname as RoutePaths) ||
    location.pathname.includes(resumeEditorBasePath)
  );
};

export default useIsTabletResponsivePage;
